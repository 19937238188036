import { Asset, FilterAssetDto, UnlinkedAsset } from '../../store/Asset';
import { PaginatedResponse } from '../../store/GeneralStore';
import { buildQueryURL } from '../../utils/helpers';
import { apiService } from '../APIService';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getAsset = (id: string) => {
  return apiService.callExternalApi<Asset>({
    url: `${API_SERVER_URL}/assets/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listAssets = (filters?: FilterAssetDto) => {
  const mergedFilters: FilterAssetDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };

  return apiService.callExternalApi<PaginatedResponse<Asset>>({
    url: buildQueryURL(mergedFilters, 'assets'),
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listAssetsByRpmAccount = (id: string) => {
  const url = `${API_SERVER_URL}/assets/rpm-account/${id}`;
  return apiService.callExternalApi<Asset[]>({
    url: url,
    method: 'GET',
    headers: { 'content-type': 'application/json' }
  });
};

export const listUnlinkedAssets = (accountId: string) => {
  return apiService.callExternalApi<UnlinkedAsset[]>({
    url: `${API_SERVER_URL}/assets/unlinked/${accountId}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
