import { DetailsDisplay } from './DetailsDisplay';
import { useLocation } from 'react-router-dom';
import { getTitle, subTitle } from './detailsDrawersHelpers';
import { Drawer } from '../Drawer/Drawer';
import { useEffect, useState } from 'react';
import { DrawerState, getDrawerOpenSubject, getDrawersSubject, updateCanClearSubject } from '../../../store/Drawer';

export function DetailsDrawer() {
  const [drawers, setDrawers] = useState<DrawerState[]>([]);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const drawerStateSub = getDrawersSubject().subscribe((s) => setDrawers(s));
    const drawerOpenSub = getDrawerOpenSubject().subscribe((drawerOpen) => setShowDrawer(drawerOpen));

    // If we've come from a page that set can clear to false, re-set it here.
    updateCanClearSubject(true);

    return () => {
      if (drawerStateSub) drawerStateSub.unsubscribe();
      if (drawerOpenSub) drawerOpenSub.unsubscribe();
    };
  }, []);

  if (!drawers.length) return <div></div>;

  const {
    id,
    data,
    title,
    titleKey,
    componentName,
    subtitleKey,
    showButtons,
    link,
    showIcon,
    showExpand,
    fullPageLink
  } = drawers[0];

  const state = {
    data: data,
    prevpath: location.pathname,
    title: title,
    componentName: componentName
  };

  return (
    <Drawer
      id={id}
      data-testid="details-drawer"
      show={showDrawer}
      title={title || getTitle(data, titleKey)}
      subtitle={subTitle(componentName, data, subtitleKey || '')}
      showButtons={showButtons}
      link={link}
      showIcon={showIcon}
      componentName={componentName}
      state={state}
      showExpand={showExpand}
      fullPageLink={fullPageLink}>
      <DetailsDisplay
        data={data}
        componentName={componentName}
      />
    </Drawer>
  );
}
