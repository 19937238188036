const DEFAULT_CARD_CLASSES = [
  'relative',
  'rounded-xl',
  'border',
  'border-grey-2',
  'text-slate-500',
  'overflow-x-clip',
  'overflow-y-visible'
].join(' ');

export interface CardProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  enableShadow?: boolean;
  onClick?: () => void;
}

export function Card({ enableShadow = false, className, children, ...props }: CardProps) {
  const shadowClass = enableShadow ? 'shadow-md' : '';

  return (
    <div
      {...props}
      className={`${DEFAULT_CARD_CLASSES} ${shadowClass} ${className}`}>
      {children}
    </div>
  );
}
