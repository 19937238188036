import { RpmAccount, FilterRpmAccountDto } from '../../store/RpmAccount';
import { PaginatedResponse } from '../../store/GeneralStore';
import { buildQueryURL } from '../../utils/helpers';
import { apiService } from '../APIService';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getRpmAccount = (id: string) => {
  return apiService.callExternalApi<RpmAccount>({
    url: `${API_SERVER_URL}/rpm-accounts/${id}`,
    method: 'GET',
    headers: { 'content-type': 'application/json' }
  });
};

export const listRpmAccounts = (filters?: FilterRpmAccountDto) => {
  const mergedFilters: FilterRpmAccountDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };

  return apiService.callExternalApi<PaginatedResponse<RpmAccount>>({
    url: buildQueryURL(mergedFilters, 'rpm-accounts'),
    method: 'GET',
    headers: { 'content-type': 'application/json' }
  });
};

export const listRpmAccountsByAsset = (id: string) => {
  const url = `${API_SERVER_URL}/rpm-accounts/asset/${id}`;
  return apiService.callExternalApi<RpmAccount[]>({
    url: url,
    method: 'GET',
    headers: { 'content-type': 'application/json' }
  });
};
