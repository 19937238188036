import { Customer, FilterCustomerDto } from '../../store/Customer';
import { PaginatedResponse } from '../../store/GeneralStore';
import { buildQueryURL } from '../../utils/helpers';
import { apiService } from '../APIService';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getCustomer = (id: string) => {
  return apiService.callExternalApi<Customer>({
    url: `${API_SERVER_URL}/account/customers/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listCustomers = (filters?: FilterCustomerDto) => {
  const mergedFilters: FilterCustomerDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };

  return apiService.callExternalApi<PaginatedResponse<Customer>>({
    url: buildQueryURL(mergedFilters, 'account/customers'),
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
