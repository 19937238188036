import { DrawerData } from '../../../store/Drawer';
import { Text } from '../../Atoms/Text';

export enum ComponentName {
  ASSET = 'asset',
  USER = 'user',
  RPM_ACCOUNT = 'rpmAccount'
}
export interface DetailsMeta {
  title: string;
  subtitleKey: string;
  componentName: ComponentName;
  idKey?: string;
  error?: string;
  link?: string;
  showIcon?: boolean;
  replace?: boolean;
  showExpand?: boolean;
  fullPageLink?: string;
}

export const getMetaData = (name: ComponentName, id: string): DetailsMeta => {
  switch (name) {
    case ComponentName.USER:
      return {
        title: 'User Details',
        subtitleKey: 'lastName',
        componentName: ComponentName.USER,
        replace: true
      };
    case ComponentName.ASSET:
      return {
        title: 'Asset',
        subtitleKey: 'number',
        componentName: ComponentName.ASSET,
        link: `${process.env.REACT_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Asset/${id}/view`,
        showIcon: true,
        replace: true
      };
    case ComponentName.RPM_ACCOUNT:
      return {
        title: 'RPM Account',
        subtitleKey: 'accountId',
        componentName: ComponentName.RPM_ACCOUNT,
        link: `${process.env.REACT_APP_RPM_URL}/rpm/Page/CustomerAccount.aspx?Item=${id}`,
        showIcon: true,
        replace: false,
        showExpand: true,
        fullPageLink: `/rpm-account/${id}`
      };
  }
};

export const subTitle = (componentName: ComponentName, data: DrawerData, subtitleKey: string) => {
  switch (componentName) {
    case 'user': {
      return (
        <Text size="lg">
          {`${data['firstName' as keyof DrawerData] || ''} ${data['lastName' as keyof DrawerData] || ''}`}
        </Text>
      );
    }
    default:
      return <Text size="lg">{data[subtitleKey as keyof DrawerData]}</Text>;
  }
};

export const getTitle = (data: DrawerData, titleKey?: string) => {
  return titleKey ? String(data[titleKey as keyof DrawerData]) : '';
};
