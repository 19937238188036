import { User } from '../../../../../store/User';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { EditUserForm } from './EditUserForm';

export interface UserDetailsParams {
  data: User;
}

export function UserDetails({ data }: UserDetailsParams) {
  return (
    <div
      className="space-y-4"
      data-testid="user-details-drawer">
      <EditUserForm data={data} />
      <DetailViewCard
        key="admin_events"
        title="History">
        <div className="p-6">user events section</div>
        {/* <UserEventsSection userData={userData} /> */}
      </DetailViewCard>
    </div>
  );
}
