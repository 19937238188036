import {
  DataGridPro,
  GridColDef,
  GridPinnedColumnFields,
  GridSingleSelectColDef,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { GridCustomColDef } from '../../types';
import { CustomToolbar } from './CustomToolbar';
import { useEffect, useState } from 'react';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { CustomFilterPanel } from './CustomFilterPanel';
import { getCurrentPreferences, GridPreferences, PrefKey, updateUser, UserPreferences } from '../../../store/User';
import { Icon } from '../../Atoms/Icon';
import { ApiFilter, updateGridRef } from '../../../store/GeneralStore';

export interface UpstackDataGridProps {
  title: string;
  columns: GridCustomColDef[] | GridSingleSelectColDef[] | GridColDef[] | undefined;
  rows: GridValidRowModel[];
  loadingData: boolean;
  page: PrefKey;
  gridSettings: GridPreferences;
  showSearch: boolean;
  pinnedColumns?: GridPinnedColumnFields;
  dataCy?: string;
  apiFilter?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFunction?: (filters: any) => void;
  filterKeys?: string[];
  rowCount?: number;
}

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    title: string;
    showSearch: boolean;
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    saveState: () => void;
  }
}

export const DEFAULT_TABLE_STYLES = {
  sx: {
    height: '40rem',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '.MuiDataGrid-cell': {
      fontFamily: 'sans-serif'
    },
    '.MuiDataGrid-overlayWrapper': {
      minHeight: '10rem'
    },
    '.MuiDataGrid-columnHeader': {
      fontFamily: 'sans-serif',
      fontSize: '.75rem',
      color: '#878EBE'
    }
  },
  headerHeight: 40,
  rowHeight: 40
};

const columnsIcon = () => {
  return <Icon type="columnsIcon" />;
};

const exportIcon = () => {
  return <Icon type="exportIcon" />;
};

const filterIcon = () => {
  return <Icon type="filterIcon" />;
};

export const UpstackDataGrid = ({
  title,
  rows,
  columns,
  loadingData,
  page,
  gridSettings = {} as GridPreferences,
  showSearch = false,
  pinnedColumns,
  dataCy = '',
  apiFilter = false,
  fetchFunction,
  filterKeys,
  rowCount
}: UpstackDataGridProps) => {
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStatePro>();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>();
  const [filterableColumns, setFilterableColumns] = useState<
    GridCustomColDef[] | GridSingleSelectColDef[] | GridColDef[]
  >();
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [stateDirty, setStateDirty] = useState(false);

  const saveState = async () => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      const settingsCopy = JSON.parse(JSON.stringify(gridSettings));
      const update = { ...settingsCopy, muiConfig: currentState };
      const currentSettings: UserPreferences = getCurrentPreferences() || ({} as UserPreferences);

      if (currentSettings?.content) {
        (currentSettings.content[page] as GridPreferences) = update;
        await updateUser({ preferences: currentSettings });
        setStateDirty(false);
      }
    }
  };

  const handleTableChange = async () => {
    setStateDirty(true);

    if (apiFilter && fetchFunction) {
      const currentState = apiRef.current.exportState();

      const filters = (currentState.filter?.filterModel?.items || []).reduce<ApiFilter>((filterSet, item) => {
        if (filterKeys?.includes(item.field)) filterSet[item.field] = item.value;
        return filterSet;
      }, {} as ApiFilter);

      filters.page = (currentState.pagination?.paginationModel?.page || 0) + 1;
      filters.pageSize = currentState.pagination?.paginationModel?.pageSize || 100;

      fetchFunction(filters);
    }
  };

  useEffect(() => {
    updateGridRef(page, apiRef);
  }, [apiRef]);

  useEffect(() => {
    const confirmNavigation = (event: { preventDefault: () => void; returnValue: string }) => {
      if (stateDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved table changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', confirmNavigation);
    return () => {
      window.removeEventListener('beforeunload', confirmNavigation);
    };
  }, [stateDirty]);

  useEffect(() => {
    if (apiRef.current) {
      const state: GridInitialStatePro = JSON.parse(
        JSON.stringify(gridSettings.muiConfig || ({} as GridInitialStatePro))
      );

      if (pinnedColumns) {
        state.pinnedColumns = pinnedColumns;
        const { columns = {} } = state;
        const { left = [], right = [] } = pinnedColumns;
        const orderedFields = [
          ...left,
          ...(state?.columns?.orderedFields || []).filter((field) => !left.includes(field) && !right.includes(field)),
          ...right
        ];
        columns.orderedFields = orderedFields;
        state.columns = columns;
      }
      setInitialState(state);

      const hiddenColumns = state?.columns?.columnVisibilityModel
        ? Object.keys(state?.columns?.columnVisibilityModel).filter(
            (field) => !(state?.columns?.columnVisibilityModel || {})[field]
          )
        : [];
      setHiddenColumns(hiddenColumns);
    }
  }, [gridSettings, apiRef.current]);

  useEffect(() => {
    if (initialState && apiRef?.current?.restoreState) {
      apiRef.current.restoreState(initialState);
      setStateDirty(false);
    }
  }, [initialState]);

  useEffect(() => {
    if (columns && hiddenColumns) {
      const updatedColumns = columns.map((column) => ({
        ...column,
        filterable:
          !hiddenColumns.includes(column.field) &&
          !['details', 'detailCTA'].includes(column.field) &&
          column.filterable != false
      }));
      setFilterableColumns(updatedColumns);
    } else {
      setFilterableColumns([]);
    }
  }, [columns, hiddenColumns?.length]);

  return (
    <DataGridPro
      rows={rows}
      data-cy={dataCy}
      showCellVerticalBorder
      showColumnVerticalBorder
      paginationMode={apiFilter ? 'server' : 'client'}
      data-testid={`${title}-data-grid`}
      columns={filterableColumns || []}
      apiRef={apiRef}
      columnHeaderHeight={DEFAULT_TABLE_STYLES.headerHeight}
      rowHeight={DEFAULT_TABLE_STYLES.rowHeight}
      sx={DEFAULT_TABLE_STYLES.sx}
      pagination
      loading={loadingData || !initialState || !filterableColumns}
      disableRowSelectionOnClick
      initialState={initialState}
      slots={{
        toolbar: CustomToolbar,
        filterPanel: CustomFilterPanel,
        columnMenuIcon: columnsIcon,
        exportIcon: exportIcon,
        openFilterButtonIcon: filterIcon
      }}
      slotProps={{
        panel: {
          anchorEl: filterButtonEl
        },
        toolbar: {
          showSearch,
          title,
          setFilterButtonEl,
          saveState
        }
      }}
      onFilterModelChange={handleTableChange}
      onPaginationModelChange={handleTableChange}
      onSortModelChange={() => setStateDirty(true)}
      onColumnOrderChange={() => setStateDirty(true)}
      onColumnVisibilityModelChange={(columnVisibility) => {
        const updatedHiddenColumns = Object.keys(columnVisibility).filter((field) => !columnVisibility[field]);
        setHiddenColumns(updatedHiddenColumns);
        setStateDirty(true);
      }}
      pageSizeOptions={[50, 100, 200]}
      rowCount={rowCount}
    />
  );
};
