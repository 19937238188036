import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { BehaviorSubject } from 'rxjs';

export interface CallResult<T> {
  data: T | null;
  error: { message: string; errorResponse?: AxiosError } | null;
}

export type ApiUrl = string;
export type CallExternalApiOptions = Omit<AxiosRequestConfig, 'url'> & {
  url: ApiUrl;
};

class APIService {
  private token: string | null = null;
  private _hasToken$ = new BehaviorSubject<boolean>(false);

  setToken(token: string) {
    this.token = token;
    this._hasToken$.next(true);
  }

  clearToken() {
    this.token = null;
    this._hasToken$.next(false);
  }

  get hasToken$(): BehaviorSubject<boolean> {
    return this._hasToken$;
  }

  async callExternalApi<T>(config: CallExternalApiOptions, skipAuth = false): Promise<CallResult<T>> {
    if (!this.token && !skipAuth) {
      return {
        data: null,
        error: { message: 'Missing token' }
      };
    }

    try {
      const response = await axios({
        ...config,
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        headers: {
          ...config.headers,
          ...(this.token && !skipAuth ? { Authorization: `Bearer ${this.token}` } : {})
        }
      });

      return { data: response?.data, error: null };
    } catch (error) {
      return {
        data: null,
        error: {
          message: (error as AxiosError).message,
          errorResponse: error as AxiosError
        }
      };
    }
  }
}

export const apiService = new APIService();
