import { InvalidLink, Link, LinkingGroupItem } from '../../store/Link';
import { apiService } from '../APIService';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const createLinkData = (linkData: Link) => {
  return apiService.callExternalApi<Link>({
    url: `${API_SERVER_URL}/links`,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: linkData
  });
};

export const deleteLinkData = (id: string) => {
  return apiService.callExternalApi<Link>({
    url: `${API_SERVER_URL}/links/${id}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listInvalidLinks = () => {
  return apiService.callExternalApi<InvalidLink[]>({
    url: `${API_SERVER_URL}/links/invalid-links`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listLinkingGroups = (accountId: string) => {
  return apiService.callExternalApi<LinkingGroupItem[][]>({
    url: `${API_SERVER_URL}/links/groups/${accountId}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
