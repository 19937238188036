import { Outlet, Route, Routes } from 'react-router-dom';
import { Dashboard } from './Pages/Dashboard';
import { CustomerPage } from './Pages/Customer';
import { Processing } from './Pages/Processing';
import { Admin } from './Pages/Admin';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { ComponentSpinner } from './Compounds/Loading/ComponentSpinner';
import { getCurrentUser, UserRole } from '../store/User';
import { CustomerDetail } from './Pages/Customer/CustomerDetail';
import { AssetPage } from './Pages/Asset';
import { RPMAccountDetailPage } from './Pages/RPM/RPMAccountDetailPage';

export const ProtectedRoute = () => {
  const { authState } = useOktaAuth();

  if (!authState || !authState?.isAuthenticated || authState?.isPending) {
    return (
      <div className="m-auto">
        <ComponentSpinner label="Loading..." />
      </div>
    );
  }

  return <Outlet />;
};

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="login/callback"
        element={<LoginCallback />}
      />
      <Route element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Dashboard />}
        />
        <Route
          path={'/*'}
          element={<Dashboard />}
        />
        <Route
          path="/customer"
          element={<CustomerPage />}
        />
        <Route
          path={'/customer/:id'}
          element={<CustomerDetail />}
        />
        <Route
          path={'/rpm-account/:id'}
          element={<RPMAccountDetailPage />}
        />
        <Route
          path="/processing"
          element={<Processing />}
        />
        <Route
          path="/asset"
          element={<AssetPage />}
        />
        {getCurrentUser()?.role === UserRole.ADMIN && (
          <Route
            path="/admin"
            element={<Admin />}
          />
        )}
      </Route>
    </Routes>
  );
}
