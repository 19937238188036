import { GridCellParams } from '@mui/x-data-grid-pro';
import { GridCustomColDef } from '../../types';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';

export const DefaultCustomerGridColumnDefs: GridCustomColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    width: 300,
    filterable: true,
    renderCell: (params: GridCellParams) => (
      <Link
        className="text-indigo"
        title="Fullscreen"
        to={`/customer/${params.row.id}`}>
        <div className="flex items-center text-small">
          <LinkIcon sx={{ mr: 1, color: '#7F7F7F' }} />
          {params.value as string}
        </div>
      </Link>
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'segment',
    headerName: 'Customer Segment',
    filterable: true,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'partnershipSegmentation',
    headerName: 'Partnership Segmentation',
    filterable: true,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'salesAgent',
    headerName: 'Sales Agent',
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'commissionsExperienceAnalyst',
    headerName: 'Commissions Experience Analyst',
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'customerSuccessManager',
    headerName: 'Customer Success Manager',
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'billingAddress',
    headerName: 'Billing Address',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'shippingAddress',
    headerName: 'Shipping Address',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'assetCount',
    headerName: 'Assets',
    filterable: true,
    flex: 1,
    description: 'Drag to move column',
    type: 'number'
  },
  {
    field: 'rpmAccountCount',
    headerName: 'RPM Accounts',
    filterable: true,
    flex: 1,
    description: 'Drag to move column',
    type: 'number'
  },
  {
    field: 'sf_id',
    headerName: 'Salesforce ID',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'id',
    headerName: 'UPSTACK ID',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'parentCustomer',
    headerName: 'Parent Customer',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'ultimateParent',
    headerName: 'Ultimate Parent',
    filterable: false,
    flex: 1,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'childCustomers',
    headerName: 'Child Customers',
    filterable: true,
    flex: 1,
    description: 'Drag to move column',
    type: 'number',
    hideByDefault: true
  }
];
