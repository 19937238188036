import { useState } from 'react';
import { DetailViewCard } from '../../Compounds/CardWithTitle/DetailViewCard';
import { DetailPanelParams, FieldLabelMap } from '../../Compounds/DetailPanel/DetailPanel';
import { Asset } from '../../../store/Asset';
import { RPMMiniTable } from '../../Compounds/RPM/RPMMiniTable';
import { DetailPanelContainer } from '../../Compounds/DetailPanel/DetailPanelContainer';
import { NewLinkDialog } from '../../Compounds/Linking/NewLinkDialog/NewLinkDialog';
import { DetailsLinkCTA } from '../../Compounds/DetailsLinkCTA';

export const FIELD_LABEL_MAP: FieldLabelMap = {
  id: 'ID',
  name: 'Name',
  sf_id: 'SFID',
  accountId: 'Account ID',
  supplierId: 'Supplier ID',
  customer: 'Customer',
  tsd: 'TSD',
  supplierName: 'Supplier',
  salesAgent: 'Sales Agent',
  commissionsExperienceAnalyst: 'Commissions Experience Analyst',
  customerSuccessManager: 'CSM',
  contract: 'Contract',
  serviceStatus: 'Service Status',
  product: 'Product',
  contractSignDate: 'Contract Sign Date',
  termLength: 'Term Length',
  installDate: 'Install Date',
  location: 'Location',
  effectiveDate: 'Effective Date',
  supplierOrderNumber: 'Supplier Order Number',
  estimatedDeliveryDate: 'Estimated Delivery Date',
  accountNumber: 'Account Number',
  activationDate: 'Activation Date',
  expectedRPMAccountNumber: 'Expected RPM Account Number',
  initialInvoiceDate: 'Initial Invoice Date',
  tsdTrackingNumber: 'TSD Tracking Number',
  serviceEndDate: 'Service End Date',
  circuitID: 'Circuit ID',
  cancelledDate: 'Cancelled Date',
  disconnectDate: 'Disconnect Date'
};

export interface AssetDetailsParams {
  data: Asset;
}

export const AssetDetails = ({ data }: AssetDetailsParams) => {
  const [isLinkFormOpen, setIsLinkFormOpen] = useState<boolean>(false);

  const sections: DetailPanelParams[] = [
    {
      data,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: ['customer', 'tsd', 'salesAgent', 'supplierName']
    },
    {
      data,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: ['commissionsExperienceAnalyst', 'customerSuccessManager']
    },
    {
      data,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: [
        'contract',
        'serviceStatus',
        'product',
        'contractSignDate',
        'termLength',
        'installDate',
        'location',
        'effectiveDate',
        'supplierOrderNumber',
        'estimatedDeliveryDate',
        'accountNumber',
        'activationDate',
        'expectedRPMAccountNumber',
        'initialInvoiceDate',
        'tsdTrackingNumber',
        'serviceEndDate',
        'circuitID',
        'cancelledDate',
        '',
        'disconnectDate'
      ]
    }
  ];

  const cardActions = () => {
    return <DetailsLinkCTA setIsFormOpen={() => setIsLinkFormOpen(true)} />;
  };

  return (
    <div
      className="space-y-4"
      data-testid="asset-details-drawer">
      <DetailViewCard
        enableCollapse
        key="asset_detail"
        title="Info">
        <div className="min-h-[25rem]">
          <DetailPanelContainer data={sections} />
        </div>
      </DetailViewCard>
      <DetailViewCard
        childrenWrapperClass="bg-slate-200"
        enableCollapse
        key="commission_detail"
        title="Commissions">
        <>Place holder</>
      </DetailViewCard>
      <DetailViewCard
        childrenWrapperClass="bg-slate-200"
        enableCollapse
        key="ticket_detail"
        title="Tickets">
        <>Place holder</>
      </DetailViewCard>
      <DetailViewCard
        cardActions={cardActions()}
        enableCollapse
        key="rpm_account_detail"
        title="RPM Accounts">
        <RPMMiniTable id={data.id} />
      </DetailViewCard>
      <NewLinkDialog
        open={isLinkFormOpen}
        setOpen={setIsLinkFormOpen}
        asset={data}
      />
    </div>
  );
};
