import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DetailCTARenderer } from '../../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { standardOperators } from '../../../Compounds/UpstackDataGrid/helpers';
import { v4 } from 'uuid';
import { ComponentName, getMetaData } from '../../../Compounds/DetailsDrawer/detailsDrawersHelpers';

export const DefaultInvalidLinkGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: '',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => {
      return params.row.asset ? (
        <DetailCTARenderer
          data={params}
          state={{
            id: v4(),
            data: params.row.asset,
            ...getMetaData(ComponentName.ASSET, params.row.sfdcUpstackId)
          }}
          value={''}
          valueFormatted={''}
        />
      ) : (
        <div />
      );
    },
    hideable: false,
    disableReorder: true
  },
  {
    field: 'assetNumber',
    headerName: 'Asset Number',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) =>
      params.row.asset ? params.row.assetNumber : <div className="text-red">⚠️ Missing Asset</div>,
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'assetSupplier',
    headerName: 'Asset Supplier',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'rpmAccountId',
    headerName: 'RPM Account Id',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'rpmAccountNumber',
    headerName: 'RPM Account Number',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'rpmSupplierName',
    headerName: 'RPM Supplier Name',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];
