import { useEffect, useState } from 'react';
import {
  GridPreferences,
  User,
  fetchUsers,
  getCruxUsersLoadingSubject,
  getCruxUsersSubject,
  getDefaultPreferences,
  getUserSubject
} from '../../../../../store/User';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultUserGridColumnDefs } from '../../ColumnDefs/UserGridColumnDefs';
import { UpstackDataGrid } from '../../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../../../Compounds/UpstackDataGrid/helpers';

export const UserTab = () => {
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userColumns, setUserColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['userGrid'] as GridPreferences
  );

  useEffect(() => {
    const loadingSubscription = getCruxUsersLoadingSubject().subscribe((loading) => setUsersLoading(loading));
    const usersSub = getCruxUsersSubject().subscribe((usrs) => setUsers(usrs));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.userGrid) {
        setGridSettings(usr.preferences.content.userGrid);
      }
    });

    fetchUsers();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (usersSub) usersSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!usersLoading && users.length > 0) {
      setUserColumns(getGridColumns(users, DefaultUserGridColumnDefs, 'userGrid'));
    }
  }, [usersLoading, users.length]);

  return (
    <div data-testid="user-management">
      <UpstackDataGrid
        title="Users"
        rows={users}
        columns={userColumns}
        loadingData={usersLoading}
        page="userGrid"
        gridSettings={gridSettings}
        showSearch={false}
      />
    </div>
  );
};
