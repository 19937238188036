import { Text, TextSpan } from '../../Atoms/Text';
import { Icon } from '../../Atoms/Icon';
import { SideBarItem } from './SideBarItem';
import { useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { getCurrentUser, UserRole } from '../../../store/User';

export const SideNavBar = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();

  const name = location.pathname.replace('/', '');
  const changeExpanded = (): void => setExpanded(!expanded);

  const handleSignOut = async () => {
    await oktaAuth.signOut();
  };

  return (
    <>
      {authState?.isAuthenticated ? (
        <div
          data-testid="side-nav-bar-container"
          className="sidebar-wrapper z-10 max-h-0 text-white">
          <div
            className={classNames('sidebar min-h-screen border-r hover:shadow-lg min-w bg-grey-1', {
              'w-[3.35rem]': !expanded,
              'w-48': expanded
            })}
            data-testid="sidebar">
            <div className="flex h-screen flex-col justify-between pb-6">
              <div>
                <div className="pl-3 border-b border-gray-200 w-full overflow-hidden">
                  <Link
                    className="flex items-center ml-auto min-w-[10rem] with-rectangle after:bg-sky-50 after:w-3"
                    title="Home"
                    to={'/'}>
                    <img
                      src={'/UPSTACK-Logo.png'}
                      className="h-14 mr-3 my-2"
                      alt="UPSTACK Logo"
                    />
                    {expanded && (
                      <TextSpan
                        weight="black"
                        size="sm14"
                        color="black">
                        Commissions Portal
                      </TextSpan>
                    )}
                  </Link>
                </div>
                <ul className="pt-4 space-y-2 tracking-wide">
                  <SideBarItem
                    navLinkProps={{ to: '' }}
                    iconProps={{ type: 'homeIcon' }}
                    textProps={{ children: 'Dashboard' }}
                    active={name === ''}
                    dataCy="dashboard-sidebar"
                    key="dashboard-sidebar"
                  />
                  <SideBarItem
                    navLinkProps={{ to: '/customer' }}
                    iconProps={{ type: 'customersIcon' }}
                    textProps={{ children: 'Customers' }}
                    active={name === 'customer'}
                    dataCy="customers-sidebar"
                    key="customers-sidebar"
                  />
                  <SideBarItem
                    navLinkProps={{ to: '/asset' }}
                    iconProps={{ type: 'inventoryIcon' }}
                    textProps={{ children: 'Assets' }}
                    active={name === 'asset'}
                    dataCy="asset-sidebar"
                    key="asset-sidebar"
                  />
                  <SideBarItem
                    navLinkProps={{ to: '/tickets' }}
                    iconProps={{ type: 'ticketsIcon' }}
                    textProps={{ children: 'Tickets' }}
                    active={name === 'tickets'}
                    dataCy="tickets-sidebar"
                    key="tickets-sidebar"
                  />
                  <SideBarItem
                    navLinkProps={{ to: '/processing' }}
                    iconProps={{ type: 'processingIcon' }}
                    textProps={{ children: 'Processing' }}
                    active={name === 'processing'}
                    dataCy="processing-sidebar"
                    key="processing-sidebar"
                  />
                  {getCurrentUser()?.role === UserRole.ADMIN && (
                    <SideBarItem
                      navLinkProps={{ to: '/admin' }}
                      iconProps={{ type: 'adminIcon' }}
                      textProps={{ children: 'Admin' }}
                      active={name === 'admin'}
                      dataCy="admin-sidebar"
                      key="admin-sidebar"
                    />
                  )}
                </ul>
              </div>
              <div className="-mb-5 border-t w-full flex justify-center">
                <button
                  className="flex items-center space-x-4 m-2 p-2 pl-1 text-gray-600 hover:bg-greenActive w-full overflow-hidden whitespace-nowrap rounded"
                  onClick={handleSignOut}>
                  <Icon type="logoutIcon" />
                  <Text
                    dataTestId="logout-button"
                    color="black"
                    size="base"
                    weight="normal">
                    Log Out
                  </Text>
                </button>
              </div>
            </div>
            <button
              className={classNames('expand-button arrow-sidebar z-10', { 'reverse-button': expanded })}
              onClick={changeExpanded}
            />
          </div>
          <button
            aria-label="expand-button-external"
            className={classNames('expand-button arrow-sidebar', { 'reverse-button': expanded })}
            onClick={changeExpanded}
          />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
