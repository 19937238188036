import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../../utils/styleHelpers';
import { createLink, Link, LinkStatus } from '../../../../store/Link';
import { useSnackbar } from '../../../../Context/SnackbarContext';
import { TextBlock } from '../../../Atoms/Text';
import { SearchBox } from '../../Search/Search';
import { fetchRpmAccountsByAsset, RpmAccount } from '../../../../store/RpmAccount';
import { Asset, fetchAssetsByRpmAcctId } from '../../../../store/Asset';
import { SearchResult } from '../../../../store/Search';
import { Icon } from '../../../Atoms/Icon';
import { getCurrentUser } from '../../../../store/User';

export interface NewLinkDialogParams {
  open: boolean;
  setOpen: (state: boolean) => void;
  asset?: Asset;
  rpmAccount?: RpmAccount;
}

export const NewLinkDialog = ({ open, setOpen, asset, rpmAccount }: NewLinkDialogParams) => {
  const { setSnack } = useSnackbar();
  const [newSfdcUpstackId, setNewSfdcUpstackId] = useState(asset?.id || '');
  const [newRpmAccountId, setNewRpmAccountId] = useState(rpmAccount?.accountId || '');
  const user = getCurrentUser();

  const handleClose = () => {
    if (!asset?.id) setNewSfdcUpstackId('');
    if (!rpmAccount?.accountId) setNewRpmAccountId('');
    setOpen(false);
  };

  const handleSelect = (result: SearchResult) => {
    if (result.source === 'asset') {
      setNewSfdcUpstackId(result.id);
    } else if (result.source === 'rpm_account') {
      setNewRpmAccountId(result.id);
    }
  };

  const handleSubmitForm = async () => {
    if (user) {
      const newLink: Link = {
        rpmAccountId: Number(newRpmAccountId),
        sfdcUpstackId: newSfdcUpstackId,
        status: LinkStatus.ACTIVE,
        createdBy: user,
        linkEvents: []
      };

      const { data, error } = await createLink(newLink);
      if (data) {
        setSnack({ message: 'Link was created successfully', type: 'success', open: true });
        asset?.id ? fetchRpmAccountsByAsset(newSfdcUpstackId) : fetchAssetsByRpmAcctId(newRpmAccountId);
        handleClose();
      }
      if (error) {
        setSnack({ message: `There was an error creating link: \n${error.message}`, type: 'error', open: true });
      }
    }
  };

  return (
    <Dialog
      data-testid="new-link-dialog"
      maxWidth="md"
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{ sx: { overflowY: 'visible' } }}>
      <DialogTitle>
        <TextBlock
          weight="bold"
          size="lg"
          color="grey9">
          New Link
        </TextBlock>
      </DialogTitle>
      <DialogContent
        sx={{ minHeight: 70, display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'visible' }}>
        <div className="space-y-3 justify-center w-1/2 mr-3">
          <TextBlock color="grey5">Asset</TextBlock>
          {!asset?.id && (
            <SearchBox
              handleSelect={handleSelect}
              searchEntityType="asset"
            />
          )}
          {!!asset?.id && (
            <div className="flex gap-1">
              <Icon
                className="p-2"
                type="assetSmall"
              />
              {asset.supplierName || ''}: {asset.number || ''}
            </div>
          )}
        </div>
        <div className="space-y-3 justify-center w-1/2">
          <TextBlock color="grey5">RPM Account</TextBlock>
          {!rpmAccount?.accountId && (
            <SearchBox
              handleSelect={handleSelect}
              searchEntityType="rpm_account"
            />
          )}
          {!!rpmAccount?.accountId && (
            <div className="flex gap-1">
              <Icon
                className="p-2"
                type="rpmAccountSmall"
              />
              {rpmAccount.supplier}: {rpmAccount.accountId}
            </div>
          )}
        </div>
      </DialogContent>
      <hr className="mb-1 mx-6" />
      <DialogActions sx={{ marginRight: 2, pb: 2 }}>
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={handleClose}>
          Cancel
        </button>
        <button
          data-testid="submit-new-link"
          disabled={!newSfdcUpstackId || !newRpmAccountId}
          onClick={handleSubmitForm}
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          type="submit">
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};
