import { useEffect, useState } from 'react';
import { GridPreferences, getDefaultPreferences, getUserSubject } from '../../../../../store/User';
import {
  InvalidLink,
  fetchInvalidLinks,
  getCruxInvalidLinksLoadingSubject,
  getCruxInvalidLinksSubject
} from '../../../../../store/Link';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultInvalidLinkGridColumnDefs } from '../../ColumnDefs/InvalidLinkGridColumnDefs';
import { UpstackDataGrid } from '../../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../../../Compounds/UpstackDataGrid/helpers';
import { PageCard } from '../../../../Compounds/CardWithTitle';

export const InvalidLinkTab = () => {
  const [invalidLinksLoading, setInvalidLinksLoading] = useState<boolean>(false);
  const [invalidLinks, setInvalidLinks] = useState<InvalidLink[]>([]);
  const [invalidLinkColumns, setInvalidLinkColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['invalidLinkGrid'] as GridPreferences
  );

  useEffect(() => {
    const loadingSubscription = getCruxInvalidLinksLoadingSubject().subscribe((loading) =>
      setInvalidLinksLoading(loading)
    );
    const invalidLinksSub = getCruxInvalidLinksSubject().subscribe((links) => setInvalidLinks(links));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.invalidLinkGrid) {
        setGridSettings(usr.preferences.content.invalidLinkGrid);
      }
    });

    fetchInvalidLinks();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (invalidLinksSub) invalidLinksSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!invalidLinksLoading && invalidLinks.length > 0) {
      setInvalidLinkColumns(getGridColumns(invalidLinks, DefaultInvalidLinkGridColumnDefs, 'invalidLinkGrid'));
    }
  }, [invalidLinksLoading, invalidLinks.length]);

  return (
    <PageCard
      dataTestId="invalid-link-management"
      dataCy="invalid-link-management"
      childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
      title="Invalid Links">
      <UpstackDataGrid
        title="Invalid Links"
        rows={invalidLinks}
        columns={invalidLinkColumns}
        loadingData={invalidLinksLoading}
        page="invalidLinkGrid"
        gridSettings={gridSettings}
        showSearch={true}
      />
    </PageCard>
  );
};
