import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { BasicDataGrid } from '../Table/BasicDataGrid';
import { Icon } from '../../Atoms/Icon';
import { Text } from '../../Atoms/Text';
import { useEffect, useState } from 'react';
import {
  Asset,
  fetchAssetsByRpmAcctId,
  getAssetsByRpmAcctLoadingSubject,
  getAssetsByRpmAcctSub
} from '../../../store/Asset';
import { addDrawer } from '../../../store/Drawer';
import { v4 } from 'uuid';
import { ComponentName, getMetaData } from '../DetailsDrawer/detailsDrawersHelpers';
import { Button } from '@mui/material';
import { deleteLink } from '../../../store/Link';
import { useSnackbar } from '../../../Context/SnackbarContext';

export interface AssetMiniTableParams {
  id: string;
}

export const AssetMiniTable = ({ id }: AssetMiniTableParams) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnack } = useSnackbar();

  const handleAssetClick = (row: Asset) => {
    addDrawer({
      id: v4(),
      data: row,
      ...getMetaData(ComponentName.ASSET, row.sf_id),
      replace: false
    });
  };

  const handleRemove = async (row: Asset) => {
    if (row.linkId) {
      await deleteLink(row.linkId);
      setSnack({ message: 'Link removed', type: 'success', open: true });
      fetchAssetsByRpmAcctId(id);
    }
  };

  useEffect(() => {
    const assetSub = getAssetsByRpmAcctSub().subscribe((r) => setAssets(r));
    const assetLoadingSub = getAssetsByRpmAcctLoadingSubject().subscribe((l) => setLoading(l));

    return () => {
      if (assetSub) assetSub.unsubscribe();
      if (assetLoadingSub) assetLoadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchAssetsByRpmAcctId(id);
  }, [id]);

  const tableCols: GridColDef[] = [
    {
      headerName: 'Number',
      field: 'number',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <button
          className="flex items-center mt-2"
          onClick={() => handleAssetClick(params.row)}>
          <Icon
            className="p-3 mr-1"
            type="assetMed"
          />
          <Text
            size="sm14"
            color="indigo">
            {params?.row.number as string}
          </Text>
        </button>
      )
    },
    { headerName: 'Customer', field: 'customer', flex: 1 },
    { headerName: 'Product', field: 'productname', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          size="small"
          color="primary"
          onClick={() => handleRemove(params.row)}
          sx={{
            fontSize: '.8rem'
          }}>
          Remove
        </Button>
      )
    }
  ];

  return (
    <div
      className="w-full p-4"
      data-testid="asset-mini-table-container">
      {loading && <ComponentSpinner label="Loading Asset Data" />}
      {!loading && (
        <BasicDataGrid
          sxStyle={{
            overflowX: 'auto',
            overflowY: 'auto',
            '.MuiDataGrid-cell': {
              fontFamily: 'sans-serif'
            },
            '.MuiDataGrid-overlayWrapper': {
              minHeight: '5rem'
            },
            '.MuiDataGrid-columnHeader': {
              fontFamily: 'sans-serif',
              fontSize: '.75rem',
              color: '#878EBE'
            }
          }}
          className="flex"
          rows={assets}
          columns={tableCols}
          dataTestId="asset-mini-data-grid"
        />
      )}
    </div>
  );
};
