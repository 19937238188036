import { useEffect, useState } from 'react';
import { Text } from '../../Atoms/Text';
import { TextField } from '@mui/material';
import { format } from 'date-fns';

export interface LabelValueFieldParams {
  label: string;
  value: string | number | boolean | null;
  save?: () => void;
  canEdit?: boolean;
}

export const LabelValueField = ({ label, value, save, canEdit = false }: LabelValueFieldParams) => {
  const [mode, setMode] = useState<'read' | 'edit'>('read');
  const [displayValue, setDisplayValue] = useState('None');

  const handleBlur = () => {
    setMode('read');
    if (save) save();
  };

  useEffect(() => {
    let val = value;
    if ((value !== false && !value) || /^\s*$/.test(String(value)) || value === null) val = 'None';
    if (label?.toLocaleLowerCase().includes('date') && value) val = format(String(value), 'PPpp');
    setDisplayValue(String(val));
  }, [value]);

  return (
    <>
      {label ? (
        <div
          className="w-1/2 flex flex-col mb-1 pr-9 pb-6"
          data-testid="labelValueField-container">
          {mode === 'read' && (
            <>
              <Text
                dataTestId={`${label}-label-field`}
                size="sm14"
                color="grey5">
                {label}
              </Text>
              <Text
                className={`${canEdit && 'cursor-pointer'}`}
                dataTestId={`${label}-read-only-field`}
                onClick={() => canEdit && setMode(mode === 'read' ? 'edit' : 'read')}
                size="sm14"
                color={displayValue === 'None' ? 'grey3' : 'grey7'}>
                {displayValue}
              </Text>
            </>
          )}
          {mode === 'edit' && (
            <TextField
              data-testid={`${label}-edit-field`}
              label={label}
              variant="outlined"
              value={value}
              onBlur={handleBlur}
            />
          )}
        </div>
      ) : (
        <div className="w-1/2 flex flex-col mb-1 pr-9 pb-6"></div>
      )}
    </>
  );
};
