import { useEffect, useState } from 'react';
import { Customer } from '../../../store/Customer';
import { LabelValueField } from './LabelValueField';
import { Asset } from '../../../store/Asset';
import { RpmAccount } from '../../../store/RpmAccount';

export interface FieldLabelMap {
  [key: string]: string;
}

export interface Detail {
  label: string;
  value: string | number;
}

export interface DetailPanelParams {
  data: Customer | Asset | RpmAccount;
  canEdit: boolean;
  fieldMap: FieldLabelMap;
  containerClasses?: string;
  order: string[];
}

export const DetailPanel = ({ data, canEdit, fieldMap, order }: DetailPanelParams) => {
  const [detailData, setDetailData] = useState<Detail[]>([]);

  const formatData = () => {
    const details = order.reduce<Detail[]>((acc, k) => {
      const val = data[k as keyof typeof data];
      acc.push({ label: fieldMap[k], value: val });
      return acc;
    }, []);

    setDetailData(details);
  };

  useEffect(() => formatData(), [data]);

  return (
    <>
      {detailData.length ? (
        <div
          className="w-full flex flex-wrap pt-6 px-6 [&:not(:last-child)]:border-b"
          data-testid="detail-panel">
          {detailData.map((d) => (
            <LabelValueField
              key={`${d.label}-${d.value}`}
              label={d.label}
              value={d.value}
              canEdit={canEdit}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
