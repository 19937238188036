import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from '../../PageLayout';
import { sections } from '../../Compounds/RPM/RPMAccountDetail';
import { DetailViewCard } from '../../Compounds/CardWithTitle/DetailViewCard';
import { DetailPanelContainer } from '../../Compounds/DetailPanel/DetailPanelContainer';
import { useEffect, useState } from 'react';
import { fetchRpmAccount, getRpmAccountSubject, RpmAccount } from '../../../store/RpmAccount';
import { AssetMiniTable } from '../../Compounds/Asset/AssetMiniTable';
import { DetailsLinkCTA } from '../../Compounds/DetailsLinkCTA';
import { NewLinkDialog } from '../../Compounds/Linking/NewLinkDialog/NewLinkDialog';
import { isAdmin } from '../../../store/User';
import { LabelValueField } from '../../Compounds/DetailPanel/LabelValueField';
import { ComponentName, getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { v4 } from 'uuid';
import { addDrawer, updateCanClearSubject } from '../../../store/Drawer';

export const RPMAccountDetailPage = () => {
  const { id } = useParams();
  const { link } = getMetaData(ComponentName.RPM_ACCOUNT, id || '');
  const [data, setData] = useState<RpmAccount>({} as RpmAccount);
  const [isLinkFormOpen, setIsLinkFormOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const cardActions = () => {
    return <DetailsLinkCTA setIsFormOpen={() => setIsLinkFormOpen(true)} />;
  };

  const handleBack = () => {
    updateCanClearSubject(false);
    addDrawer({
      id: v4(),
      data,
      ...getMetaData(ComponentName.RPM_ACCOUNT, data.accountId)
    });
    navigate(-1);
  };

  useEffect(() => {
    const rpmSub = getRpmAccountSubject().subscribe((d) => setData(d));
    return () => {
      if (rpmSub) rpmSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchRpmAccount(id);
  }, [id]);

  const pageActions = () => {
    return (
      <button
        onClick={handleBack}
        className={DEFAULT_ADVANCE_BUTTON_STYLE}>
        Back
      </button>
    );
  };

  return (
    <PageLayout
      className="mb-10"
      dataTestId="rpm-account-detail-page"
      subTitle="RPM Account"
      pageTitle={data.accountId}
      showLink={true}
      link={link}
      showIcon={true}
      icon="rpmAccountMed"
      pageActions={pageActions()}>
      <div className="grid grid-cols-3 mt-2 space-x-6">
        <div className="space-y-6">
          <DetailViewCard
            enableCollapse
            key="commissions_detail"
            title="Commissions">
            <div className="flex justify-center items-center min-h-20">Place holder</div>
          </DetailViewCard>
          <DetailViewCard
            cardActions={cardActions()}
            enableCollapse
            key="asset_table"
            title="Assets">
            <AssetMiniTable id={data.accountId} />
          </DetailViewCard>
        </div>
        <div className="space-y-6">
          <DetailViewCard
            enableCollapse
            key="ticket_detail"
            title="Tickets">
            <div className="flex justify-center items-center min-h-20">Place holder</div>
          </DetailViewCard>
          <DetailViewCard
            enableCollapse
            key="comments_detail"
            title="Comments">
            <div className="flex justify-center items-center min-h-20">Place holder</div>
          </DetailViewCard>
        </div>
        <div className="space-y-6">
          <DetailViewCard
            enableCollapse
            key="rpm-account-detail-card"
            title="Info">
            <div className="min-h-[15rem]">
              <DetailPanelContainer data={sections(data)} />
            </div>
          </DetailViewCard>
          {isAdmin() && (
            <DetailViewCard
              enableCollapse
              key="admin_detail"
              title="Admin">
              <div className="flex px-6 pt-6 w-full items-center">
                <LabelValueField
                  label="RPM Account ID"
                  value={data.accountId}
                />
                <LabelValueField
                  label="SFDC SR #"
                  // This field does not exist in the response yet.
                  value="123456;123457"
                />
              </div>
            </DetailViewCard>
          )}
        </div>
        <NewLinkDialog
          open={isLinkFormOpen}
          setOpen={setIsLinkFormOpen}
          rpmAccount={data}
        />
      </div>
    </PageLayout>
  );
};
