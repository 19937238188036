import { User } from '../../store/User';
import { apiService } from '../APIService';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getUser = () => {
  return apiService.callExternalApi<User>({
    url: `${API_SERVER_URL}/users/info`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const updateUserData = (userData: User) => {
  return apiService.callExternalApi<User>({
    url: `${API_SERVER_URL}/users/${userData.id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(userData)
  });
};

export const listUsers = () => {
  return apiService.callExternalApi<User[]>({
    url: `${API_SERVER_URL}/users`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
