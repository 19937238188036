import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { listLinkEvents } from '../Api/LinkEvent/LinkEventApi';
import { addError } from './Error';
import { ApiFilter, PaginatedResponse } from './GeneralStore';

interface LinkAction {
  createdAt: string;
  file?: Blob;
  fileName?: string;
  id: string;
  operation: string;
  origin: string;
  updatedAt: string;
}

export interface LinkEvent {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  sfdcUpstackId: string;
  rpmAccountId: string;
  linkAction: LinkAction;
}

export interface FilterLinkEventDto extends ApiFilter {
  content?: string;
}

const cruxLinkEventsSubject = new BehaviorSubject<PaginatedResponse<LinkEvent>>({} as PaginatedResponse<LinkEvent>);
const cruxLinkEventsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getCruxLinkEventsSubject = (): BehaviorSubject<PaginatedResponse<LinkEvent>> => cruxLinkEventsSubject;
export const getCruxLinkEventsLoadingSubject = (): BehaviorSubject<boolean> => cruxLinkEventsLoadingSubject;

export const fetchLinkEvents = async (filters?: FilterLinkEventDto) => {
  cruxLinkEventsLoadingSubject.next(true);

  const { data, error } = await listLinkEvents(filters);

  if (data) cruxLinkEventsSubject.next(data);
  if (error) addError('link-event', error.message);

  cruxLinkEventsLoadingSubject.next(false);
};
