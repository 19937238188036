import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getAsset, listAssets, listAssetsByRpmAccount, listUnlinkedAssets } from '../Api/Asset/AssetApi';
import { addError } from './Error';
import { ApiFilter, PaginatedResponse } from './GeneralStore';

export interface Asset {
  number: string | null;
  id: string;
  sf_id: string;
  accountId: string | null;
  name: string;
  supplierId: string;
  supplierName: string;
  customer: string;
  tsd: string;
  salesAgent: string | null;
  commissionsExperienceAnalyst: string | null;
  customerSuccessManager: string | null;
  assetStatus: string;
  commissionStatus: string | null;
  actualDecommissionedDate: string | null;
  initialInvoiceDate: string | null;
  contractSignDate: string;
  disconnectDate: string | null;
  expectedDisconnectDate: string | null;
  installDate: string;
  expectedRpmAccountNumber: string | null;
  accountNumber: string | null;
  supplierOrderNumber: string | null;
  tsdTrackingNumber: string | null;
  linkId?: string;
}

export interface FilterAssetDto extends ApiFilter {
  number?: string;
  sf_id?: string;
  accountId?: string;
  name?: string;
  supplierId?: string;
  id?: string;
  customer?: string;
  tsd?: string;
  supplierName?: string;
  salesAgent?: string;
  commissionsExperienceAnalyst?: string;
  customerSuccessManager?: string;
  assetStatus?: string;
  commissionStatus?: string;
  actualDecommissionedDate?: string;
  initialInvoiceDate?: string;
  contractSignDate?: string;
  disconnectDate?: string;
  expectedDisconnectDate?: string;
  installDate?: string;
}

export interface UnlinkedAsset {
  id: string;
  assetNumber: string;
  commissionStatus: string;
  status: string;
  supplierName: string;
}

// Asset subjects
const assetSubject = new BehaviorSubject<Asset>({} as Asset);
const assetDetailSubject = new BehaviorSubject<Asset>({} as Asset);
const cruxAssetsSubject = new BehaviorSubject<PaginatedResponse<Asset>>({} as PaginatedResponse<Asset>);
const assetsByRpmAcctSub = new BehaviorSubject<Asset[]>([] as Asset[]);
const unlinkedAssetSub = new BehaviorSubject<UnlinkedAsset[]>([] as UnlinkedAsset[]);

// Loading Subjects
const assetLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxAssetsLoadingSubject = new BehaviorSubject<boolean>(false);
const assetsByRpmAcctLoadingSubject = new BehaviorSubject<boolean>(false);
const unlinkedAssetLoadingSubject = new BehaviorSubject<boolean>(false);

const clearError = () => addError('asset', undefined);

export const getAssetSubject = (): BehaviorSubject<Asset> => assetSubject;
export const getAssetLoadingSubject = (): BehaviorSubject<boolean> => assetLoadingSubject;

export const getAssetDetailSubject = (): BehaviorSubject<Asset> => assetDetailSubject;

export const getCruxAssetsSubject = (): BehaviorSubject<PaginatedResponse<Asset>> => cruxAssetsSubject;
export const getCruxAssetsLoadingSubject = (): BehaviorSubject<boolean> => cruxAssetsLoadingSubject;

export const getAssetsByRpmAcctSub = (): BehaviorSubject<Asset[]> => assetsByRpmAcctSub;
export const getAssetsByRpmAcctLoadingSubject = (): BehaviorSubject<boolean> => assetsByRpmAcctLoadingSubject;

export const getUnlinkedAssetSub = (): BehaviorSubject<UnlinkedAsset[]> => unlinkedAssetSub;
export const getUnlinkedAssetLoadingSubject = (): BehaviorSubject<boolean> => unlinkedAssetLoadingSubject;

export const updateAssetDetail = (assetData: Asset) => assetDetailSubject.next(assetData);

export const getAssetById = async (id: string): Promise<Asset> => {
  const asset = cruxAssetsSubject?.getValue()?.data?.find((a) => a?.id === id);

  if (asset) {
    return asset;
  } else {
    const { data, error } = await getAsset(id);
    if (error) addError('asset', error.message);
    if (data) return data;
  }

  return {} as Asset;
};

export const fetchAsset = async (id: string) => {
  assetLoadingSubject.next(true);

  const { data, error } = await getAsset(id);

  if (data) {
    clearError();
    assetSubject.next(data);
  }
  if (error) addError('asset', error.message);

  assetLoadingSubject.next(false);
};

export const fetchAssets = async (filters?: FilterAssetDto) => {
  cruxAssetsLoadingSubject.next(true);

  const { data, error } = await listAssets(filters);

  if (data) {
    clearError();
    cruxAssetsSubject.next(data);
  }
  if (error) addError('asset', error.message);

  cruxAssetsLoadingSubject.next(false);
};

export const fetchAssetsByRpmAcctId = async (id: string) => {
  assetsByRpmAcctLoadingSubject.next(true);

  const { data, error } = await listAssetsByRpmAccount(id);

  if (data) {
    clearError();
    assetsByRpmAcctSub.next(data);
  }
  if (error) addError('asset', error.message);

  assetsByRpmAcctLoadingSubject.next(false);
};

export const fetchUnlinkedAssets = async (id: string) => {
  unlinkedAssetLoadingSubject.next(true);

  const { data, error } = await listUnlinkedAssets(id);

  if (data) {
    clearError();
    unlinkedAssetSub.next(data);
  }
  if (error) addError('asset', error.message);

  unlinkedAssetLoadingSubject.next(false);
};
