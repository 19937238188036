import { BehaviorSubject } from 'rxjs';
import { Asset } from './Asset';
import { RpmAccount } from './RpmAccount';
import { User } from './User';
import { ComponentName } from '../components/Compounds/DetailsDrawer/detailsDrawersHelpers';

export type DrawerData = Asset | User | RpmAccount;

export enum DrawerDataType {
  ASSET = 'asset',
  USER = 'user',
  RPM_ACCOUNT = 'rpmAccount'
}

export interface DrawerState {
  id: string;
  data: DrawerData;
  title: string;
  subtitleKey: string;
  componentName: ComponentName;
  link?: string;
  showButtons?: boolean;
  showIcon?: boolean;
  titleKey?: string;
  saveAction?: (data: DrawerData) => void;
  replace?: boolean;
  showExpand?: boolean;
  fullPageLink?: string;
}

const drawersSubject = new BehaviorSubject<DrawerState[]>([]);
const drawerOpenSubject = new BehaviorSubject<boolean>(false);
const canClearSubject = new BehaviorSubject<boolean>(true);

export const getDrawersSubject = (): BehaviorSubject<DrawerState[]> => drawersSubject;
export const getDrawerOpenSubject = (): BehaviorSubject<boolean> => drawerOpenSubject;
export const getCanClearSubject = (): BehaviorSubject<boolean> => canClearSubject;

export const updateDrawerOpenSubject = (newStatus: boolean) => drawerOpenSubject.next(newStatus);
export const updateCanClearSubject = (newStatus: boolean) => canClearSubject.next(newStatus);
export const clearDrawerState = () => canClearSubject.getValue() && drawersSubject.next([]);

export const addDrawer = (data: DrawerState) => {
  if (data.replace) drawersSubject.next([data]);
  else drawersSubject.next([data, ...drawersSubject.getValue()]);
  drawerOpenSubject.next(true);
};

export const removeDrawer = (id: string) => {
  const currentDrawers = [...drawersSubject.getValue()];
  const updatedDrawers = currentDrawers.filter((item) => item.id !== id);
  drawersSubject.next(updatedDrawers);
  if (updatedDrawers.length === 0) updateDrawerOpenSubject(false);
};
