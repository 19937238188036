import { useState } from 'react';
import { DetailViewCard } from '../../Compounds/CardWithTitle/DetailViewCard';
import { DetailPanelParams } from '../../Compounds/DetailPanel/DetailPanel';
import { RpmAccount } from '../../../store/RpmAccount';
import { DetailPanelContainer } from '../DetailPanel/DetailPanelContainer';
import { AssetMiniTable } from '../Asset/AssetMiniTable';
import { DetailsLinkCTA } from '../../Compounds/DetailsLinkCTA';
import { NewLinkDialog } from '../Linking/NewLinkDialog/NewLinkDialog';

export interface RPMAccountDetailsParams {
  data: RpmAccount;
}

export const sections = (data: RpmAccount): DetailPanelParams[] => [
  {
    data,
    canEdit: false,
    fieldMap: {
      supplier: 'RPM Supplier',
      accountCustomerName: 'RPM Customer',
      source: 'Source',
      salesforceCustomer: 'Salesforce Customer',
      dateAdded: 'Added'
    },
    order: ['salesforceCustomer', 'source', 'accountCustomerName', 'supplier', 'dateAdded']
  },
  {
    data,
    canEdit: false,
    fieldMap: {
      rep: 'Assigned Rep',
      agent: 'Assigned Agent'
    },
    order: ['rep', 'agent']
  },
  {
    data,
    canEdit: false,
    fieldMap: {
      circuits: 'Circuit IDs',
      address: 'Address'
    },
    order: ['circuits', 'address']
  }
];

export const RPMAccountDetails = ({ data }: RPMAccountDetailsParams) => {
  const [isLinkFormOpen, setIsLinkFormOpen] = useState<boolean>(false);

  const cardActions = () => {
    return <DetailsLinkCTA setIsFormOpen={() => setIsLinkFormOpen(true)} />;
  };

  return (
    <div
      className="space-y-4"
      data-testid="rpm-account-details-drawer">
      <DetailViewCard
        enableCollapse
        key="rpm-account-detail-card"
        title="Info">
        <div className="min-h-[15rem]">
          <DetailPanelContainer data={sections(data)} />
        </div>
      </DetailViewCard>
      <DetailViewCard
        childrenWrapperClass="bg-slate-200"
        enableCollapse
        key="commissions_detail"
        title="Commissions">
        <>Place holder</>
      </DetailViewCard>
      <DetailViewCard
        childrenWrapperClass="bg-slate-200"
        enableCollapse
        key="ticket_detail"
        title="Tickets">
        <>Place holder</>
      </DetailViewCard>
      <DetailViewCard
        cardActions={cardActions()}
        enableCollapse
        key="asset_table"
        title="Assets">
        <AssetMiniTable id={data.accountId} />
      </DetailViewCard>
      <NewLinkDialog
        open={isLinkFormOpen}
        setOpen={setIsLinkFormOpen}
        rpmAccount={data}
      />
    </div>
  );
};
