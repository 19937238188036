import { useParams } from 'react-router-dom';
import { PageLayout } from '../../PageLayout';
import { useEffect, useState } from 'react';
import { Customer, getCustomerById, getCustomerSubject } from '../../../store/Customer';
import { UpstackBreadcrumbs } from '../../Compounds/Breadcrumbs/Breadcrumbs';
import { FieldLabelMap } from '../../Compounds/DetailPanel/DetailPanel';
import { TicketPanel } from './TicketPanel';
import { AdminPanel } from './AdminPanel';
import { isAdmin } from '../../../store/User';
import { TextSpan } from '../../Atoms/Text';
import { DetailPanelContainer } from '../../Compounds/DetailPanel/DetailPanelContainer';
import { PageCard } from '../../Compounds/CardWithTitle';
import { LinkComponent } from '../../Compounds/Linking/LinkComponent';
import { fetchLinkingGroups, getCruxLinkingGroupsSubject, LinkingGroupItem } from '../../../store/Link';
import { fetchUnlinkedAssets, getUnlinkedAssetSub, UnlinkedAsset } from '../../../store/Asset';

export const FIELD_LABEL_MAP: FieldLabelMap = {
  id: 'ID',
  name: 'Name',
  segment: 'Customer Segment',
  activeLocations: 'Active Locations',
  salesAgent: 'Sales Agent',
  assetCount: 'Assets',
  linkedAssetCount: 'Linked Asset Count',
  unlinkedAssetCount: 'Unlinked Asset Count',
  rpmAccounts: 'RPM Accounts',
  customerSuccessManager: 'CSM',
  commissionsExperienceAnalyst: 'CEA',
  billingAddress: 'Billing Address',
  customerSegment: 'Customer Segment',
  shippingAddress: 'Shipping Address',
  partnershipSegmentation: 'Partnership Segmentation',
  ultimateCustomer: 'Ultimate Customer',
  childCustomerList: 'Child Customer List',
  parentCustomer: 'Parent Customer'
};

export const CustomerDetail = () => {
  const [customerData, setCustomerData] = useState<Customer>({} as Customer);
  const { id } = useParams();
  const url = `${process.env.REACT_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Account/${id}/view`;
  const [groups, setGroups] = useState<LinkingGroupItem[][]>([]);
  const [unlinkedAssets, setUnlinkedAssets] = useState<UnlinkedAsset[]>([]);

  const getData = async () => {
    if (id) {
      await getCustomerById(id);
      fetchLinkingGroups(id);
      fetchUnlinkedAssets(id);
    }
  };

  useEffect(() => {
    const custSub = getCustomerSubject().subscribe((cust) => {
      setCustomerData(cust);
    });
    const linkSub = getCruxLinkingGroupsSubject().subscribe((g) => setGroups(g));
    const unlinkedSub = getUnlinkedAssetSub().subscribe((ua) => setUnlinkedAssets(ua));

    getData();

    return () => {
      if (custSub) custSub.unsubscribe();
      if (linkSub) linkSub.unsubscribe();
      if (unlinkedSub) unlinkedSub.unsubscribe();
    };
  }, []);

  const sections = [
    {
      data: customerData,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: ['salesAgent', 'billingAddress', 'customerSegment', 'shippingAddress', 'partnershipSegmentation']
    },
    {
      data: customerData,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: ['commissionsExperienceAnalyst', 'customerSuccessManager']
    },
    {
      data: customerData,
      canEdit: false,
      fieldMap: FIELD_LABEL_MAP,
      order: ['ultimateCustomer', 'childCustomerList', 'parentCustomer']
    }
  ];

  return (
    <PageLayout
      className="mb-10"
      dataTestId="Customer-detail-page"
      subTitle="Customer"
      pageTitle={customerData?.name}
      showLink={true}
      link={url}
      showIcon={true}
      icon="customerMed">
      <UpstackBreadcrumbs
        crumbs={[
          { label: 'Customers', href: '/customer' },
          { label: `${customerData?.name}`, href: `/customer/${customerData?.id}` }
        ]}
      />
      <div className="w-full flex mt-3 space-x-6">
        <div className="w-8/12 space-y-6">
          <PageCard title="Commissions">
            <div className="flex h-[20rem] border rounded-xl bg-slate-100">
              <TextSpan className="m-auto">Commissions Component</TextSpan>
            </div>
          </PageCard>
          <PageCard title="Linking">
            <LinkComponent
              groups={groups}
              initialVisibleCount={4}
              unlinkedItems={unlinkedAssets}
            />
          </PageCard>
        </div>
        <div className="w-4/12 self-start space-y-6">
          <PageCard title="Info">
            <DetailPanelContainer data={sections} />
          </PageCard>
          <TicketPanel />

          {/* comment component placeholder */}
          <div className="flex h-[10rem] border rounded-xl bg-slate-100">
            <TextSpan className="m-auto">Comments</TextSpan>
          </div>

          {isAdmin() && <AdminPanel data={customerData} />}
        </div>
      </div>
    </PageLayout>
  );
};
