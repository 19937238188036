import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useSnackbar } from '../Context/SnackbarContext';

type ErrorDomainKeys =
  | 'user'
  | 'general'
  | 'commission'
  | 'customer'
  | 'link-event'
  | 'link'
  | 'asset'
  | 'rpm-account'
  | 'search';
type ErrorsByDomain = Record<ErrorDomainKeys, string | undefined>;

export interface UseErrorStoreReturn {
  errorMessageSubscription: typeof userErrorMessageSubject;
  errorsByDomain: ErrorsByDomain;
}

const defaultErrorByDomain: ErrorsByDomain = {
  user: undefined,
  general: undefined,
  commission: undefined,
  customer: undefined,
  'link-event': undefined,
  link: undefined,
  search: undefined,
  asset: undefined,
  'rpm-account': undefined
};

const defaultUserErrorMessage = undefined;
const errorByDomainSubject = new BehaviorSubject<ErrorsByDomain>(defaultErrorByDomain);
const userErrorMessageSubject = new BehaviorSubject<string | undefined>(defaultUserErrorMessage);

const updateErrorMessage = (message: string): void => userErrorMessageSubject.next(message);
export const getErrorMessageSubject = () => userErrorMessageSubject;
export const addError = (domain: ErrorDomainKeys, message: string | undefined): void => {
  errorByDomainSubject.next({ ...errorByDomainSubject.value, [domain]: message });
};
export const clearError = (domain: ErrorDomainKeys): void => {
  errorByDomainSubject.next({ ...errorByDomainSubject.value, [domain]: undefined });
};
export const resetErrorStore = (): void => {
  errorByDomainSubject.next(defaultErrorByDomain);
  userErrorMessageSubject.next(defaultUserErrorMessage);
};

export const useErrorStore = (): UseErrorStoreReturn => {
  const [errorsByDomain, setErrorsByDomain] = useState<ErrorsByDomain>(defaultErrorByDomain);
  const { setSnack } = useSnackbar();

  const handleErrorsByDomainUpdate = (): void => {
    const errorsArray = Object.keys(errorsByDomain).reduce((msgs: string[], key: string): string[] => {
      const currError = errorsByDomain[key as unknown as ErrorDomainKeys];
      if (currError && !msgs.includes(currError)) msgs.push(currError);

      return msgs;
    }, []);
    const errors = errorsArray.join(', \n');

    if (errors) {
      updateErrorMessage(errors);
      if (setSnack) setSnack({ message: errors || '', type: 'error', open: true });
    }
  };

  useEffect(() => {
    const domainErrorSub = errorByDomainSubject.subscribe((errors) => {
      setErrorsByDomain(errors);
    });

    return () => {
      if (domainErrorSub) domainErrorSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    handleErrorsByDomainUpdate();
  }, [JSON.stringify(errorsByDomain)]);

  return { errorMessageSubscription: userErrorMessageSubject, errorsByDomain };
};
