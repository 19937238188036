import {
  GridFilterItem,
  GridFilterOperator,
  GridSingleSelectColDef,
  getGridSingleSelectOperators,
  getGridStringOperators,
  getGridBooleanOperators,
  GridValidRowModel
} from '@mui/x-data-grid-pro';
import { getCurrentPreferences, GridPreferences, PrefKey, User } from '../../../store/User';
import { InputNumberInterval } from './BetweenFilterInput';
import { AutoCompleteFilter } from './AutoComplete';
import { getNestedValue } from '../../../utils/helpers';
import { Commission } from '../../../store/Commission';
import { Customer } from '../../../store/Customer';
import { Asset } from '../../../store/Asset';
import { LinkEvent } from '../../../store/LinkEvent';
import { InvalidLink } from '../../../store/Link';

export const getValuesForColumn = (rows: GridValidRowModel[], field: string) => {
  return rows
    .reduce((acc, ele: GridValidRowModel) => {
      const val = field.includes('.') ? getNestedValue(ele, field) : ele[field];
      if (val && !acc.includes(val)) acc.push(val);
      return acc;
    }, [])
    .sort();
};

export const getGridColumns = (
  data: User[] | Commission[] | Customer[] | Asset[] | LinkEvent[] | InvalidLink[],
  defaultCols: GridSingleSelectColDef[],
  grid: string
): GridSingleSelectColDef[] => {
  const currentSettings = getCurrentPreferences();
  const gridPrefs = currentSettings?.content?.[grid as PrefKey] as GridPreferences;

  return defaultCols.reduce((acc: GridSingleSelectColDef[], col: GridSingleSelectColDef) => {
    const filterValues = getValuesForColumn(data, col.field);
    if (Array.isArray(filterValues)) col.valueOptions = filterValues;
    if (!gridPrefs?.disabledFields?.includes(col?.field)) acc.push(col);
    return acc;
  }, []);
};

const betweenOperator: GridFilterOperator = {
  label: 'between',
  value: 'between',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
      return null;
    }
    if (!filterItem.value[0] || !filterItem.value[1]) {
      return null;
    }
    return (value): boolean => {
      return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
    };
  },
  InputComponent: InputNumberInterval
};

export const standardOperators = (): GridFilterOperator[] => {
  const isEmpty = getGridStringOperators().find((op) => op.value === 'isEmpty') as GridFilterOperator;
  const allOperators = [...getGridSingleSelectOperators(), isEmpty];

  allOperators.forEach((o) => {
    if (o?.value && !['between', 'isEmpty', 'isAnyOf'].includes(o?.value)) {
      o.InputComponent = AutoCompleteFilter;
    }
  });

  return allOperators;
};

export const booleanOperators = (): GridFilterOperator[] => {
  return getGridBooleanOperators();
};

export const allOperators = (): GridFilterOperator[] => {
  return [...standardOperators(), betweenOperator];
};
