import { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from './components/AppRoutes';
import { NavBar } from './components/Compounds/Navigation/NavBar';
import { SideNavBar } from './components/Compounds/Navigation/SideNavBar';
import { fetchUser, getUserLoadingSubject } from './store/User';
import { LicenseInfo } from '@mui/x-license';
import { LoadingIconPage } from './components/Compounds/Loading/loadingIcon';
import { useErrorStore } from './store/Error';
import { useOktaAuth } from '@okta/okta-react';
import { DetailsDrawer } from './components/Compounds/DetailsDrawer/DetailsDrawer';
import { useLocation } from 'react-router-dom';
import { clearDrawerState } from './store/Drawer';
import { apiService } from './Api/APIService';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { combineLatest } from 'rxjs';

export const App = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');
  const [showLoading, setShowLoading] = useState(false);
  const location = useLocation();
  const { errorMessageSubscription } = useErrorStore();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) return;

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }

    if (authState?.isAuthenticated) {
      apiService.setToken(authState.accessToken?.accessToken || '');
      fetchUser();
    }
  }, [authState, authState?.isAuthenticated]);

  useEffect(() => {
    const loadingSubscription = combineLatest([getUserLoadingSubject(), apiService.hasToken$]).subscribe(
      ([usersLoading, hasToken]) => {
        setShowLoading([usersLoading, !hasToken].some((loadingState) => loadingState));
      }
    );
    const errorSubscription = errorMessageSubscription.subscribe(async (errors) => {
      if (!errors) return;
      if (errors.includes('401') && !authState?.isAuthenticated) {
        try {
          apiService.clearToken();
          await oktaAuth.signInWithRedirect();
        } catch (err) {
          console.error('Error', err);
        }
      }
    });

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (errorSubscription) errorSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    clearDrawerState();
  }, [location]);

  return (
    <div
      data-testid="app-container"
      className="min-h-screen flex">
      {showLoading && <LoadingIconPage />}
      {!showLoading && (
        <>
          <SideNavBar />
          <div
            data-testid="app-container"
            className="flex flex-col basis-full main-container">
            <NavBar />
            <AppRoutes />
          </div>
          <DetailsDrawer />
        </>
      )}
    </div>
  );
};
