import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { createLinkData, deleteLinkData, listInvalidLinks, listLinkingGroups } from '../Api/Link/LinkApi';
import { addError, clearError } from './Error';
import { getAsset } from '../Api/Asset/AssetApi';
import { Asset } from './Asset';
import { User } from './User';
import { LinkEvent } from './LinkEvent';

export enum LinkStatus {
  CANDIDATE = 'Candidate',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REJECTED = 'Rejected'
}
export interface InvalidLink {
  id: string;
  sfdcUpstackId: string;
  assetNumber: string;
  assetSupplier: string;
  rpmAccountId?: number;
  rpmAccountNumber?: string;
  rpmSupplierName?: string;
  asset?: Asset;
}

export interface Link {
  id?: string;
  status: LinkStatus;
  createdBy: User;
  linkEvents: LinkEvent[];
  sfdcUpstackId: string;
  rpmAccountId: number;
  createdAt?: string;
  updatedAt?: string;
  archived?: boolean;
}

export interface LinkingGroupItem {
  sfdcUpstackId: string;
  rpmAccountId: number;
  assetSupplierName: string;
  assetNumber: string;
  assetStatus: string;
  commissionStatus: string;
  rpmAccountSupplierName: string;
  rpmAccountNumber: string;
}

const cruxInvalidLinksSubject = new BehaviorSubject<InvalidLink[]>([] as InvalidLink[]);
const cruxInvalidLinksLoadingSubject = new BehaviorSubject<boolean>(false);

const cruxLinkingGroupsLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxLinkingGroupsSubject = new BehaviorSubject<LinkingGroupItem[][]>([[]] as LinkingGroupItem[][]);

export const getCruxInvalidLinksSubject = (): BehaviorSubject<InvalidLink[]> => cruxInvalidLinksSubject;
export const getCruxInvalidLinksLoadingSubject = (): BehaviorSubject<boolean> => cruxInvalidLinksLoadingSubject;
export const getCruxLinkingGroupsSubject = (): BehaviorSubject<LinkingGroupItem[][]> => cruxLinkingGroupsSubject;
export const getCruxLinkingGroupsLoadingSubject = (): BehaviorSubject<boolean> => cruxLinkingGroupsLoadingSubject;

export const fetchInvalidLinks = async () => {
  cruxInvalidLinksLoadingSubject.next(true);

  const { data, error } = await listInvalidLinks();

  if (data) {
    clearError('link');
    const dataWithAsset = await Promise.all(
      data.map(async (link) => {
        if (!link.sfdcUpstackId) return link;

        const { data } = await getAsset(link.sfdcUpstackId);
        link.asset = data || undefined;
        return link;
      })
    );
    cruxInvalidLinksSubject.next(dataWithAsset);
  }
  if (error) addError('link', error.message);

  cruxInvalidLinksLoadingSubject.next(false);
};

export const fetchLinkingGroups = async (accountId: string) => {
  cruxLinkingGroupsLoadingSubject.next(true);

  const { data, error } = await listLinkingGroups(accountId);

  if (data) {
    clearError('link');
    cruxLinkingGroupsSubject.next(data);
  }
  if (error) addError('link', error.message);

  cruxLinkingGroupsLoadingSubject.next(false);
};

export const createLink = async (link: Partial<Link>) => {
  const linkData: Link = { ...({} as Link), ...link };

  const { data, error } = await createLinkData(linkData);

  if (data) clearError('link');
  if (error) addError('link', error.message);

  return { data, error };
};

export const deleteLink = async (id: string) => {
  const { data, error } = await deleteLinkData(id);

  if (data) clearError('link');
  if (error) addError('link', error.message);
};
