import { Tooltip } from '@mui/material';
import { Text, SrOnlyText } from '../../Atoms/Text';
import { Icon, IconType } from '../../Atoms/Icon';
import { removeDrawer } from '../../../store/Drawer';
import { useNavigate } from 'react-router';

const DEFAULT_DRAWER_CLASSES = [
  'bg-white',
  'border-l',
  'border-grey-2',
  'fixed',
  'z-50',
  'flex',
  'flex-col',
  'top-0',
  'right-0',
  'h-screen',
  'lg:w-2/5',
  'w-full',
  'lg:max-w-[50rem]',
  'p-6',
  'overflow-auto'
].join(' ');

export const ICON_MAP_MD: { [key: string]: IconType } = {
  asset: 'assetMed',
  rpmAccount: 'rpmAccountMed'
};

export interface DrawerProps {
  id: string;
  children: JSX.Element | JSX.Element[];
  show: boolean;
  title?: string;
  subtitle?: JSX.Element;
  description?: string;
  className?: string;
  style?: object;
  link?: string;
  state?: object;
  componentName?: string;
  showButtons?: boolean;
  showIcon?: boolean;
  showExpand?: boolean;
  fullPageLink?: string;
}

export const Drawer = ({
  id,
  show,
  title,
  subtitle,
  description,
  className,
  children,
  link = '',
  componentName,
  showButtons,
  showIcon,
  showExpand,
  fullPageLink,
  ...props
}: DrawerProps) => {
  const navigate = useNavigate();
  if (!show) return <></>;

  const handleClose = () => {
    removeDrawer(id);
  };

  const handleExpand = () => {
    if (fullPageLink) navigate(fullPageLink, { state: { prevPath: location.pathname } });
  };

  return (
    <div
      {...props}
      className={`${DEFAULT_DRAWER_CLASSES} ${className}`}>
      <div className="flex items-center justify-between">
        <Text
          dataCy="detail-drawer-title"
          className=""
          color="grey5">
          {title}
        </Text>
        <div className="flex space-x-2">
          {showExpand && (
            <button
              data-cy="full-page-button"
              className="border p-2 rounded-lg"
              onClick={handleExpand}>
              <Icon
                className="p-3"
                type="expand"
              />
              <SrOnlyText dataTestId="full_page_button">full page</SrOnlyText>
            </button>
          )}
          <button
            data-cy="close-drawer-button"
            className="border p-2 rounded-lg"
            onClick={handleClose}>
            <Icon
              className="p-3"
              type="cancel"
            />
            <SrOnlyText dataTestId="cancel_button">hide drawer</SrOnlyText>
          </button>
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        {componentName && showIcon && (
          <Icon
            className="p-3"
            type={ICON_MAP_MD[componentName] || ''}
          />
        )}
        <Text className="max-w-64 truncate">{subtitle}</Text>
        {link && (
          <Tooltip
            arrow
            title="Open"
            placement="bottom">
            <a
              data-testid="openInSalesforceButton"
              href={link}
              target="_blank"
              rel="noreferrer">
              <Icon type="external" />
            </a>
          </Tooltip>
        )}
      </div>
      <Text
        className="mb-[15px]"
        size="sm">
        {description}
      </Text>
      {children}
    </div>
  );
};
