import React from 'react';
import { NavBarTabs } from './NavBarTabs';
import { useOktaAuth } from '@okta/okta-react';

export const NavBar = () => {
  const { authState } = useOktaAuth();
  return (
    <>
      {authState?.isAuthenticated ? (
        <nav
          data-testid="nav-bar-container"
          className="flex py-4 px-4 border-gray-200 border-b">
          <NavBarTabs />
        </nav>
      ) : (
        <div></div>
      )}
    </>
  );
};
