import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

interface DetailsLinkCTAProps {
  setIsFormOpen: (isFormOpen: boolean) => void;
}

export function DetailsLinkCTA({ setIsFormOpen }: DetailsLinkCTAProps) {
  return (
    <>
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className="text-[indigo] text-sm"
          data-cy="new-link"
          onClick={() => setIsFormOpen(true)}>
          Link
        </button>
      </TextSpan>
    </>
  );
}
