import { useEffect, useState } from 'react';
import { PageLayout } from '../../PageLayout';
import {
  Customer,
  fetchCustomers,
  FilterCustomerDto,
  getCruxCustomersLoadingSubject,
  getCruxCustomersSubject
} from '../../../store/Customer';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { DefaultCustomerGridColumnDefs } from './CustomerGridColumnDefs';
import { getDefaultPreferences, getUserSubject, GridPreferences } from '../../../store/User';
import { Tab, Tabs, useTheme } from '@mui/material';
import { TabPanel } from '../../Atoms/TabPanel';
import { PaginatedResponse } from '../../../store/GeneralStore';

export const CustomerPage = () => {
  const [customersLoading, setCustomersLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<PaginatedResponse<Customer>>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['customerGrid'] as GridPreferences
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const tabs = [{ name: 'All' }];

  const handleFetchCustomers = (filters?: FilterCustomerDto) => {
    fetchCustomers(filters);
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    const loadingSubscription = getCruxCustomersLoadingSubject().subscribe((loading) => setCustomersLoading(loading));
    const custSub = getCruxCustomersSubject().subscribe((cus) => setCustomers(cus));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.customerGrid) {
        setGridSettings(usr.preferences.content.customerGrid);
      }
    });

    handleFetchCustomers();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (custSub) custSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  const filterKeys = DefaultCustomerGridColumnDefs.filter((column) => column.filterable).map((column) => column.field);

  return (
    <PageLayout
      dataTestId="customer-page"
      pageTitle="Customers">
      <>
        <Tabs
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgb(247 247 247)',
            borderBottom: '1px solid rgb(229 231 235)',
            borderTop: '1px solid rgb(229 231 235)',
            paddingRight: '28rem',
            maxWidth: '100%',
            marginTop: '.5rem'
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              height: selectedTab <= tabs.length - 1 ? 2 : 0,
              borderRadius: 1
            }
          }}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_e, index) => handleTabChange(index)}>
          {tabs.map((tab, index) => (
            <Tab
              sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
              key={index}
              label={`${tab.name}`}
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={`${index}`}
            index={index}
            value={selectedTab}>
            <UpstackDataGrid
              title="Customers"
              rows={customers?.data || []}
              columns={DefaultCustomerGridColumnDefs}
              loadingData={customersLoading}
              page="customerGrid"
              gridSettings={gridSettings}
              showSearch={false}
              apiFilter={true}
              fetchFunction={handleFetchCustomers}
              rowCount={customers?.totalCount}
              filterKeys={filterKeys}
            />
          </TabPanel>
        ))}
      </>
    </PageLayout>
  );
};
