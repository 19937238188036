import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getCustomer, listCustomers } from '../Api/Customer/CustomerApi';
import { addError } from './Error';
import { ApiFilter, PaginatedResponse } from './GeneralStore';

export interface Customer {
  id: string;
  sf_id: string;
  name: string;
  segment: string;
  salesAgent: string | null;
  parentCustomer: string | null;
  ultimateParent: string | null;
  customerSuccessManager: string | null;
  commissionsExperienceAnalyst: string | null;
  billingAddress: string | null;
  shippingAddress: string | null;
  assetCount: number;
  rpmAccountCount: number | null;
  childCustomers: number | null;
}

export interface FilterCustomerDto extends ApiFilter {
  segment?: string;
  name?: string;
  salesAgent?: string;
  partnershipSegmentation?: string;
  commissionsExperienceAnalyst?: string;
  customerSuccessManager?: string;
  assetCount?: string;
  rpmAccountCount?: string;
  childCustomers?: string;
}

const customerSubject = new BehaviorSubject<Customer>({} as Customer);
const cruxCustomersSubject = new BehaviorSubject<PaginatedResponse<Customer>>({} as PaginatedResponse<Customer>);
const customerLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxCustomersLoadingSubject = new BehaviorSubject<boolean>(false);

const clearError = () => addError('customer', undefined);

export const getCustomerSubject = (): BehaviorSubject<Customer> => customerSubject;
export const getCruxCustomersSubject = (): BehaviorSubject<PaginatedResponse<Customer>> => cruxCustomersSubject;
export const getCruxCustomersLoadingSubject = (): BehaviorSubject<boolean> => cruxCustomersLoadingSubject;
export const getCustomerLoadingSubject = (): BehaviorSubject<boolean> => customerLoadingSubject;

export const getCustomerById = async (id: string) => {
  const cust = cruxCustomersSubject?.getValue()?.data?.find((cus) => cus?.id === id);
  if (cust) {
    customerSubject.next(cust);
    return;
  }

  if (!cust) {
    await fetchCustomer(id);
  }
};

export const fetchCustomer = async (id: string) => {
  customerLoadingSubject.next(true);

  const { data, error } = await getCustomer(id);

  if (data) {
    clearError();
    customerSubject.next(data);
  }
  if (error) addError('customer', error.message);

  customerLoadingSubject.next(false);
};

export const fetchCustomers = async (filters?: FilterCustomerDto) => {
  cruxCustomersLoadingSubject.next(true);

  const { data, error } = await listCustomers(filters);

  if (data) {
    clearError();
    cruxCustomersSubject.next(data);
  }
  if (error) addError('customer', error.message);

  cruxCustomersLoadingSubject.next(false);
};
