import { CardButton } from '../../Buttons/CardButton';
import { addDrawer, DrawerState, updateDrawerOpenSubject } from '../../../../store/Drawer';
import { GridCellParams } from '@mui/x-data-grid-pro';

export type DetailCTARendererProps = {
  data: GridCellParams;
  value: string;
  valueFormatted: string;
  onClick?: () => void;
  state: DrawerState;
};

export function DetailCTARenderer({ data, value, valueFormatted, onClick, state }: DetailCTARendererProps) {
  const valueString: string = valueFormatted || value;

  const handleDetailClick = () => {
    addDrawer(state);
    updateDrawerOpenSubject(true);
    if (onClick) onClick();
  };

  return (
    <div className="mt-3">
      <div className="desc-wrap">{valueString}</div>
      <CardButton
        data-testid="detail-cta-button"
        data-cy="detail-cta-button"
        onClick={handleDetailClick}
        className="h-4"
        icon="details"
      />
    </div>
  );
}
