import { DetailPanel, DetailPanelParams } from './DetailPanel';

export interface DetailPanelContainerParams {
  data: DetailPanelParams[];
}

export const DetailPanelContainer = ({ data }: DetailPanelContainerParams) => {
  return (
    <div data-testid="detail-panel-container">
      {data.map((d, i) => (
        <DetailPanel
          key={`${d.data.id}-${i}`}
          data={d.data}
          canEdit={d.canEdit}
          fieldMap={d.fieldMap}
          order={d.order}
        />
      ))}
    </div>
  );
};
