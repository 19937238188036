import { Customer } from '../../../store/Customer';
import { PageCard } from '../../Compounds/CardWithTitle';
import { LabelValueField } from '../../Compounds/DetailPanel/LabelValueField';

export interface AdminPanelParams {
  data: Customer;
}

export const AdminPanel = ({ data }: AdminPanelParams) => {
  return (
    <PageCard
      dataTestId="admin-panel"
      childrenWrapperClass="rounded-xl"
      title="Admin">
      <div className="flex p-4 w-full justify-between">
        <LabelValueField
          label="Salesforce ID"
          value={data.sf_id}
        />
        <LabelValueField
          label="GRI"
          value={data.id}
        />
      </div>
    </PageCard>
  );
};
