import { useEffect, useState } from 'react';
import {
  fetchRpmAccountsByAsset,
  getCruxRpmAccountsByAssetLoadingSubject,
  getCruxRpmAccountsByAssetSubject,
  RpmAccount
} from '../../../store/RpmAccount';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import { Icon } from '../../Atoms/Icon';
import { Text } from '../../Atoms/Text';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { ComponentName, getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { addDrawer } from '../../../store/Drawer';
import { v4 } from 'uuid';

export interface RPMMiniTableParams {
  id: string;
}

export const RPMMiniTable = ({ id }: RPMMiniTableParams) => {
  const [rpmData, setRpmData] = useState<RpmAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleAcctClick = (row: RpmAccount) => {
    addDrawer({
      id: v4(),
      data: row,
      ...getMetaData(ComponentName.RPM_ACCOUNT, row.accountId)
    });
  };

  const tableCols: GridColDef[] = [
    {
      headerName: 'Number',
      field: 'accountId',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <button
          className="flex items-center"
          onClick={() => handleAcctClick(params.row)}>
          <Icon
            className="p-3 mr-1"
            type="rpmAccountMed"
          />
          <Text
            size="sm14"
            color="indigo">
            {params?.value as string}
          </Text>
        </button>
      )
    },
    { headerName: 'Name', field: 'accountCustomerName', flex: 1 },
    { headerName: 'Supplier', field: 'supplier', flex: 1 }
  ];

  useEffect(() => {
    const rpmSub = getCruxRpmAccountsByAssetSubject().subscribe((r) => setRpmData(r));
    const rpmLoadingSub = getCruxRpmAccountsByAssetLoadingSubject().subscribe((l) => setLoading(l));

    return () => {
      if (rpmSub) rpmSub.unsubscribe();
      if (rpmLoadingSub) rpmLoadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchRpmAccountsByAsset(id);
  }, [id]);

  return (
    <div
      className="w-full"
      data-testid="rpm-mini-table-container">
      {loading && <ComponentSpinner label="Loading RPM Data" />}
      {!loading && (
        <BasicDataGrid
          sxStyle={{
            overflowX: 'scroll',
            overflowY: 'scroll',
            '.MuiDataGrid-cell': {
              fontFamily: 'sans-serif'
            },
            '.MuiDataGrid-overlayWrapper': {
              minHeight: '5rem'
            },
            '.MuiDataGrid-columnHeader': {
              fontFamily: 'sans-serif',
              fontSize: '.75rem',
              color: '#878EBE'
            }
          }}
          className="flex"
          rows={rpmData}
          columns={tableCols}
          dataTestId="rpm-mini-data-grid"
        />
      )}
    </div>
  );
};
