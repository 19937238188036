import { useEffect, useState } from 'react';
import { GridPreferences, getDefaultPreferences, getUserSubject } from '../../../../../store/User';
import {
  FilterLinkEventDto,
  LinkEvent,
  fetchLinkEvents,
  getCruxLinkEventsLoadingSubject,
  getCruxLinkEventsSubject
} from '../../../../../store/LinkEvent';
import { PaginatedResponse } from '../../../../../store/GeneralStore';
import { DefaultLinkEventGridColumnDefs } from '../../ColumnDefs/LinkEventGridColumnDefs';
import { UpstackDataGrid } from '../../../../Compounds/UpstackDataGrid/UpstackDataGrid';

export const LogTab = () => {
  const [linkEventsLoading, setLinkEventsLoading] = useState<boolean>(false);
  const [linkEvents, setLinkEvents] = useState<PaginatedResponse<LinkEvent>>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['linkEventGrid'] as GridPreferences
  );

  const handleFetchLinkEvents = (filters?: FilterLinkEventDto) => {
    fetchLinkEvents(filters);
  };

  useEffect(() => {
    const loadingSubscription = getCruxLinkEventsLoadingSubject().subscribe((loading) => setLinkEventsLoading(loading));
    const linkEventsSub = getCruxLinkEventsSubject().subscribe((linkEvents) => setLinkEvents(linkEvents));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.linkEventGrid) {
        setGridSettings(usr.preferences.content.linkEventGrid);
      }
    });

    fetchLinkEvents();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (linkEventsSub) linkEventsSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  return (
    <div data-testid="link-event-management">
      <UpstackDataGrid
        title="Log"
        rows={linkEvents?.data || []}
        columns={DefaultLinkEventGridColumnDefs}
        loadingData={linkEventsLoading}
        page="linkEventGrid"
        gridSettings={gridSettings}
        showSearch={false}
        apiFilter={true}
        fetchFunction={handleFetchLinkEvents}
        filterKeys={[
          'id',
          'sfdcUpstackId',
          'rpmAccountId',
          'linkAction.origin',
          'linkAction.operation',
          'linkAction.createdAt'
        ]}
        rowCount={linkEvents?.totalCount}
      />
    </div>
  );
};
