import { GridCellParams } from '@mui/x-data-grid-pro';
import { GridCustomColDef } from '../../types';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { v4 } from 'uuid';
import { ComponentName, getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { format } from 'date-fns';
import { getDate } from '../../../utils/helpers';

export const DefaultAssetGridColumnDefs: GridCustomColDef[] = [
  {
    field: 'details',
    headerName: '',
    sortable: false,
    width: 65,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
        state={{
          id: v4(),
          data: params.row,
          ...getMetaData(ComponentName.ASSET, params.row.sf_id)
        }}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'number',
    headerName: 'Number',
    sortable: true,
    filterable: true,
    width: 170,
    description: 'Drag to move column'
  },
  {
    field: 'sf_id',
    headerName: 'SFID',
    sortable: true,
    filterable: true,
    width: 170,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'id',
    headerName: 'ID',
    sortable: true,
    filterable: true,
    width: 300,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'accountId',
    headerName: 'Account Id',
    sortable: true,
    filterable: true,
    width: 170,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'supplierId',
    headerName: 'Supplier ID',
    sortable: true,
    filterable: true,
    width: 170,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'customer',
    headerName: 'Customer',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'tsd',
    headerName: 'TSD',
    sortable: true,
    filterable: true,
    width: 70,
    description: 'Drag to move column'
  },
  {
    field: 'supplierName',
    headerName: 'Supplier',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'salesAgent',
    headerName: 'Sales Agent',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'commissionsExperienceAnalyst',
    headerName: 'CEA',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'customerSuccessManager',
    headerName: 'CSM',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'assetStatus',
    headerName: 'Service Status',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'commissionStatus',
    headerName: 'Commission Status',
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column'
  },
  {
    field: 'actualDecommissionedDate',
    headerName: 'Actual Decommissioned Date',
    sortable: true,
    filterable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    width: 100,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'initialInvoiceDate',
    headerName: 'Initial Invoice Date',
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    sortable: true,
    filterable: true,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'contractSignDate',
    headerName: 'Contract Sign Date',
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    sortable: true,
    filterable: true,
    width: 100,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'disconnectDate',
    headerName: 'Disconnect Date',
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    sortable: true,
    filterable: true,
    width: 100,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'expectedDisconnectDate',
    headerName: 'Expected Decommission Date',
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    sortable: true,
    filterable: true,
    width: 100,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'installDate',
    headerName: 'Install Date',
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    sortable: true,
    filterable: true,
    width: 100,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'expectedRpmAccountNumber',
    headerName: 'Expected RPM Account Number',
    sortable: true,
    filterable: false,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    sortable: true,
    filterable: false,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'supplierOrderNumber',
    headerName: 'Supplier Order Number',
    sortable: true,
    filterable: false,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  },
  {
    field: 'tsdTrackingNumber',
    headerName: 'TSD Tracking Number',
    sortable: true,
    filterable: false,
    width: 200,
    description: 'Drag to move column',
    hideByDefault: true
  }
];
