import { useState } from 'react';
import { LinkingGroupItem } from '../../../store/Link';
import { Card } from '../../Atoms/Card';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { Button, Tooltip } from '@mui/material';
import { addDrawer, DrawerData } from '../../../store/Drawer';
import { v4 } from 'uuid';
import { ComponentName, getMetaData } from '../DetailsDrawer/detailsDrawersHelpers';
import { getAssetById, UnlinkedAsset } from '../../../store/Asset';
import { getRpmAccountById } from '../../../store/RpmAccount';

export interface LinkComponentParams {
  groups: LinkingGroupItem[][];
  initialVisibleCount: number;
  unlinkedItems: UnlinkedAsset[];
}

export const LinkComponent = ({ groups, initialVisibleCount, unlinkedItems }: LinkComponentParams) => {
  const [visibleCount, setVisibleCount] = useState<{ groups: number; unlinked: number }>({
    groups: initialVisibleCount,
    unlinked: initialVisibleCount
  });

  const handleShowMore = (items: LinkingGroupItem[] | UnlinkedAsset[], type: string) => {
    setVisibleCount({
      ...visibleCount,
      [type]: Math.min(visibleCount[type as keyof typeof visibleCount] + initialVisibleCount, items.length)
    });
  };

  const handleCardClick = async (g: LinkingGroupItem | UnlinkedAsset, type: string) => {
    let drawerData: DrawerData | null = null;
    let componentName: ComponentName;

    switch (type) {
      case 'asset':
        drawerData = await getAssetById(g.assetNumber);
        componentName = ComponentName.ASSET;
        break;

      case 'rpm':
        if ('rpmAccountId' in g && g.rpmAccountId) {
          drawerData = await getRpmAccountById(String(g.rpmAccountId));
          componentName = ComponentName.RPM_ACCOUNT;
        } else {
          // TODO: remove the console.error uses or replace with Datadog logs
          console.error('rpmAccountId is missing in the provided object');
          return;
        }
        break;

      default:
        console.error('Invalid type provided to handleCardClick:', type);
        return;
    }

    if (!drawerData) {
      console.error('Failed to fetch drawer data');
      return;
    }

    addDrawer({
      id: v4(),
      data: drawerData,
      ...getMetaData(componentName, drawerData.id),
      replace: true
    });
  };

  const getLink = (g: LinkingGroupItem | UnlinkedAsset, type: string): string | undefined => {
    if (type === 'asset') {
      if ('sfdcUpstackId' in g && g.sfdcUpstackId) {
        return `${process.env.REACT_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Asset/${g.sfdcUpstackId}/view`;
      }
    }

    if (type === 'rpm') {
      if ('rpmAccountId' in g && g.rpmAccountId) {
        return `${process.env.REACT_APP_RPM_URL}/rpm/Page/CustomerAccount.aspx?Item=${g.rpmAccountId}`;
      }
    }
  };

  return (
    <div
      className="p-6"
      data-testid="link-component-container">
      {groups.map((g, i) => (
        <div
          key={i}
          className="grid grid-cols-4 space-x-6 mb-8 px-6 py-4 border rounded-sm">
          <div className="col-span-2">
            {g.slice(0, visibleCount.groups).map((l) => (
              <Card
                className="w-full px-4 py-2 my-4 border-indigo rounded-[7px] hover:cursor-pointer"
                key={l.sfdcUpstackId}
                onClick={() => handleCardClick(l, 'asset')}>
                <div className="w-full flex items-center space-x-4">
                  <Icon
                    type="customerMed"
                    className="p-3"
                  />
                  <div className="grid">
                    <TextSpan
                      color="indigo"
                      weight="medium"
                      size="sm14">
                      {l.assetSupplierName} {l.assetNumber}
                    </TextSpan>
                    <TextSpan
                      color="indigoMid"
                      weight="normal"
                      size="sm14">
                      {l.commissionStatus}
                    </TextSpan>
                  </div>
                  <div className="!ml-auto">
                    <Tooltip
                      arrow
                      title="Open"
                      placement="bottom">
                      <a
                        onClick={(e) => e.stopPropagation()}
                        data-testid="openInSalesforceButton"
                        href={getLink(l, 'asset')}
                        target="_blank"
                        rel="noreferrer">
                        <Icon type="external" />
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div
            className="col-span-2"
            key={i}>
            {g
              .filter((obj, index, self) => index === self.findIndex((o) => o.rpmAccountId === obj.rpmAccountId))
              .slice(0, visibleCount.groups)
              .map((l) => (
                <Card
                  className="w-full px-4 py-2 my-4 !border-green-500 rounded-[7px] hover:cursor-pointer"
                  key={l.sfdcUpstackId}
                  onClick={() => handleCardClick(l, 'rpm')}>
                  <div className="flex items-center space-x-4">
                    <Icon
                      type="rpmAccountMed"
                      className="p-3"
                    />
                    <TextSpan
                      color="indigo"
                      weight="medium"
                      size="sm14">
                      {l.rpmAccountSupplierName} {l.rpmAccountId}
                    </TextSpan>
                    <div className="!ml-auto">
                      <Tooltip
                        arrow
                        title="Open"
                        placement="bottom">
                        <a
                          onClick={(e) => e.stopPropagation()}
                          data-testid="openInRPMButton"
                          href={getLink(l, 'rpm')}
                          target="_blank"
                          rel="noreferrer">
                          <Icon type="external" />
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
          {visibleCount.groups < g.length && (
            <div className="col-span-4">
              <Button
                sx={{ width: '8rem', display: 'flex', justifySelf: 'center' }}
                size="small"
                onClick={() => handleShowMore(g, 'groups')}>
                Show More
              </Button>
            </div>
          )}
        </div>
      ))}
      <div data-testid="unlinked-asset-container">
        <TextSpan
          className="mb-0"
          size="sm14">
          Unlinked
        </TextSpan>
        {unlinkedItems.slice(0, visibleCount.unlinked).map((g, i) => (
          <Card
            data-testid={`unlinked-card-${g.assetNumber}`}
            key={g.assetNumber}
            className="w-1/2 px-4 py-2 my-4 border-indigo rounded-[7px] hover:cursor-pointer"
            onClick={() => handleCardClick(g, 'asset')}>
            <div className="w-full flex items-center space-x-4">
              <Icon
                type="customerMed"
                className="p-3"
              />
              <div className="grid">
                <TextSpan
                  color="indigo"
                  weight="medium"
                  size="sm14">
                  {g.supplierName} {g.assetNumber}
                </TextSpan>
                <TextSpan
                  color="indigoMid"
                  weight="normal"
                  size="sm14">
                  {g.commissionStatus}
                </TextSpan>
              </div>
              <div className="!ml-auto">
                <Tooltip
                  arrow
                  title="Open"
                  placement="bottom">
                  <a
                    onClick={(e) => e.stopPropagation()}
                    data-testid="openInSalesforceButton"
                    href={getLink(g, 'asset')}
                    target="_blank"
                    rel="noreferrer">
                    <Icon type="external" />
                  </a>
                </Tooltip>
              </div>
            </div>
          </Card>
        ))}
        <>
          {visibleCount.unlinked < unlinkedItems.length && (
            <div className="col-span-4">
              <Button
                sx={{ width: '8rem', display: 'flex', justifySelf: 'center' }}
                size="small"
                onClick={() => handleShowMore(unlinkedItems, 'unlinked')}>
                Show More
              </Button>
            </div>
          )}
        </>
      </div>
    </div>
  );
};
