import { ComponentName } from './detailsDrawersHelpers';
import { UserDetails } from '../../Pages/Admin/Tabs/User/UserDetails';
import { AssetDetails } from '../../Pages/Asset/AssetDetail';
import { DrawerData } from '../../../store/Drawer';
import { RPMAccountDetails } from '../RPM/RPMAccountDetail';
import { User } from '../../../store/User';
import { Asset } from '../../../store/Asset';
import { RpmAccount } from '../../../store/RpmAccount';

export interface DetailsDisplayProps {
  data: DrawerData;
  componentName?: ComponentName;
}

export const DetailsDisplay = ({ data, componentName }: DetailsDisplayProps) => {
  if (!componentName) return <div></div>;

  const getComponentByName = () => {
    switch (componentName) {
      case ComponentName.USER:
        return <UserDetails data={data as User} />;
      case ComponentName.ASSET:
        return <AssetDetails data={data as Asset} />;
      case ComponentName.RPM_ACCOUNT:
        return <RPMAccountDetails data={data as RpmAccount} />;
      default:
        return null;
    }
  };

  return getComponentByName();
};
