import { GridColDef, DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { DEFAULT_TABLE_STYLES } from '../UpstackDataGrid/UpstackDataGrid';
import { SxProps } from '@mui/material';
import { v4 } from 'uuid';
/* eslint-disable @typescript-eslint/no-explicit-any */

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    showToolbar: boolean;
  }
}

export interface Column {
  title: string;
}

export interface BasicDataGridProps {
  rows: any[];
  columns: GridColDef[];
  className?: string;
  showToolbar?: boolean;
  dataCy?: string;
  dataTestId?: string;
  sxStyle?: SxProps;
}

export interface CustomToolbarProps {
  showToolbar: boolean;
}

const CustomToolbar = ({ showToolbar }: CustomToolbarProps) => {
  return (
    <GridToolbarContainer>
      {showToolbar && (
        <GridToolbarExport
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

export const BasicDataGrid = ({
  rows,
  columns,
  className,
  showToolbar = false,
  dataCy = '',
  dataTestId = '',
  sxStyle
}: BasicDataGridProps) => {
  return (
    <div
      data-testid={dataTestId}
      className={className}>
      <DataGridPro
        rows={rows || []}
        disableRowSelectionOnClick
        disableColumnFilter
        columns={columns}
        hideFooterPagination
        hideFooterRowCount
        columnHeaderHeight={DEFAULT_TABLE_STYLES.headerHeight}
        rowHeight={DEFAULT_TABLE_STYLES.rowHeight}
        sx={sxStyle || DEFAULT_TABLE_STYLES.sx}
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: {
            showToolbar
          }
        }}
        data-cy={dataCy}
        getRowId={() => v4()}
      />
    </div>
  );
};
