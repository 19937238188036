import { LinkEvent, FilterLinkEventDto } from '../../store/LinkEvent';
import { PaginatedResponse } from '../../store/GeneralStore';
import { buildQueryURL } from '../../utils/helpers';
import { apiService } from '../APIService';

export const listLinkEvents = (filters?: FilterLinkEventDto) => {
  const mergedFilters: FilterLinkEventDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };

  return apiService.callExternalApi<PaginatedResponse<LinkEvent>>({
    url: buildQueryURL(mergedFilters, 'link-events'),
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
