import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getRpmAccount, listRpmAccountsByAsset, listRpmAccounts } from '../Api/RpmAccount/RpmAccountApi';
import { addError } from './Error';
import { ApiFilter, PaginatedResponse } from './GeneralStore';

export interface RpmAccount {
  id: string;
  name: string;
  accountId: string;
  accountNumber: string;
  supplier: string;
  supplierId: string;
}

export interface FilterRpmAccountDto extends ApiFilter {
  accountId?: string;
  accountNumber?: string;
  supplier?: string;
  supplierId?: string;
}

const rpmAccountSubject = new BehaviorSubject<RpmAccount>({} as RpmAccount);
const rpmAccountDetailSubject = new BehaviorSubject<RpmAccount>({} as RpmAccount);
const cruxRpmAccountsSubject = new BehaviorSubject<PaginatedResponse<RpmAccount>>({} as PaginatedResponse<RpmAccount>);
const cruxRpmAccountsByAssetSubject = new BehaviorSubject<RpmAccount[]>([]);
const rpmAccountLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxRpmAccountsLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxRpmAccountsByAssetLoadingSubject = new BehaviorSubject<boolean>(false);

const clearError = () => addError('rpm-account', undefined);

export const getRpmAccountSubject = (): BehaviorSubject<RpmAccount> => rpmAccountSubject;
export const getRpmAccountDetailSubject = (): BehaviorSubject<RpmAccount> => rpmAccountDetailSubject;

export const getCruxRpmAccountsSubject = (): BehaviorSubject<PaginatedResponse<RpmAccount>> => cruxRpmAccountsSubject;
export const getCruxRpmAccountsByAssetSubject = (): BehaviorSubject<RpmAccount[]> => cruxRpmAccountsByAssetSubject;

export const getRpmAccountLoadingSubject = (): BehaviorSubject<boolean> => rpmAccountLoadingSubject;
export const getCruxRpmAccountsLoadingSubject = (): BehaviorSubject<boolean> => cruxRpmAccountsLoadingSubject;
export const getCruxRpmAccountsByAssetLoadingSubject = (): BehaviorSubject<boolean> =>
  cruxRpmAccountsByAssetLoadingSubject;

export const getRpmAccountById = async (id: string): Promise<RpmAccount> => {
  const rpmAccount = cruxRpmAccountsSubject?.getValue()?.data?.find((a) => a?.id === id);

  if (rpmAccount) {
    return rpmAccount;
  } else {
    const { data, error } = await getRpmAccount(id);
    if (error) addError('rpm-account', error.message);
    if (data) return data;
  }

  return {} as RpmAccount;
};

export const updateRpmAccountDetailSubject = (rpmAccount: RpmAccount) => rpmAccountDetailSubject.next(rpmAccount);

export const fetchRpmAccount = async (id: string) => {
  rpmAccountLoadingSubject.next(true);

  const { data, error } = await getRpmAccount(id);

  if (data) {
    clearError();
    rpmAccountSubject.next(data);
  }
  if (error) addError('rpm-account', error.message);

  rpmAccountLoadingSubject.next(false);
};

export const fetchRpmAccounts = async (filters?: FilterRpmAccountDto) => {
  cruxRpmAccountsLoadingSubject.next(true);

  const { data, error } = await listRpmAccounts(filters);

  if (data) {
    clearError();
    cruxRpmAccountsSubject.next(data);
  }
  if (error) addError('rpm-account', error.message);

  cruxRpmAccountsLoadingSubject.next(false);
};

export const fetchRpmAccountsByAsset = async (id: string) => {
  cruxRpmAccountsByAssetLoadingSubject.next(true);

  const { data, error } = await listRpmAccountsByAsset(id);

  if (data) {
    clearError();
    cruxRpmAccountsByAssetSubject.next(data);
  }
  if (error) addError('rpm-account', error.message);

  cruxRpmAccountsByAssetLoadingSubject.next(false);
};
