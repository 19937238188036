import { GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

export const DefaultLinkEventGridColumnDefs: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    sortable: true,
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'sfdcUpstackId',
    headerName: 'SFID',
    sortable: true,
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'rpmAccountId',
    headerName: 'RPM Account ID',
    sortable: true,
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  },
  {
    field: 'content.message',
    headerName: 'Content',
    filterable: true,
    flex: 1,
    valueGetter: (_value, row) => row?.content?.message,
    description: 'Drag to move column'
  },
  {
    field: 'linkAction.origin',
    headerName: 'Origin',
    sortable: true,
    filterable: true,
    flex: 1,
    valueGetter: (_value, row) => row?.linkAction?.origin,
    description: 'Drag to move column'
  },
  {
    field: 'linkAction.operation',
    headerName: 'Operation',
    sortable: true,
    filterable: true,
    flex: 1,
    valueGetter: (_value, row) => row?.linkAction?.operation,
    description: 'Drag to move column'
  },
  {
    field: 'linkAction.createdAt',
    headerName: 'Created At',
    sortable: true,
    valueGetter: (_value, row) => {
      return row?.linkAction?.createdAt ? format(row?.linkAction?.createdAt, 'PPpp') : '';
    },
    filterable: true,
    flex: 1,
    description: 'Drag to move column'
  }
];
